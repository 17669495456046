import { lazy } from 'react';

// project imports
import Loadable from '../components/uiLoadable/Loadable';

const PageNotFound = Loadable(lazy(() => import('../components/404')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PageNotFoundRoutes = {
  path: '*',
  element: <PageNotFound />,
};

export default PageNotFoundRoutes;
