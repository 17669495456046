import { createSlice } from '@reduxjs/toolkit';

export interface ISearchFields {
  industry: string;
  products: string;
  services: string;
  country: string;
  stateOrProvince: string;
  city: string;
  postalCode: string;
  companyOverview: string;
  parentCompanyNameIfSubsidiary: string;
  materials: string;
  manufacturingProcessesCapability: string;
  certificationsAndQualityStandards: string;
  manufacturingLocations: string;
  esgCompliance: string;
  [key: string]: string | null; // Index signature to allow any string key
}

interface ISearchFieldsDropDownValues {
  industries: string[];
  countries: string[];
  states: string[];
  cities: string[];
  postalCodes: string[];
  certifications: string[];
  esgCompliance: string[];
}

interface initialStateTypes {
  searchFields: ISearchFields;
  searchFieldsDropDownValues: ISearchFieldsDropDownValues;
  supplierFoundFromMarketPlace: any[];
  providedParams: boolean;
}

const initialState: initialStateTypes = {
  providedParams: false,
  supplierFoundFromMarketPlace: [],
  searchFields: {
    industry: '',
    products: '',
    services: '',
    country: '',
    stateOrProvince: '',
    city: '',
    postalCode: '',
    companyOverview: '',
    parentCompanyNameIfSubsidiary: '',
    materials: '',
    manufacturingProcessesCapability: '',
    certificationsAndQualityStandards: '',
    manufacturingLocations: '',
    esgCompliance: '',
  },
  searchFieldsDropDownValues: {
    industries: [],
    countries: [],
    states: [],
    cities: [],
    postalCodes: [],
    certifications: [],
    esgCompliance: [],
  },
};

const supplierSearchSlice = createSlice({
  name: 'supplierSearch',
  initialState,
  reducers: {
    setSearchFieldsDropDownValues(state, action) {
      console.log('setSearchFieldsDropDownValues', action.payload);
      state.searchFieldsDropDownValues = action.payload;
    },
    setSearchedResults(state, action) {
      state.supplierFoundFromMarketPlace = action.payload.data || [];
    },
    setSearchFieldValue(state, action) {
      const { key, value } = action.payload as {
        key: keyof ISearchFields;
        value: string;
      };
      state.searchFields[key] = value;
    },
    resetSupplierSearch(state, action) {
      state.searchFields = {
        ...state.searchFields,
        postalCode: '',
        companyOverview: '',
        parentCompanyNameIfSubsidiary: '',
        materials: '',
        manufacturingProcessesCapability: '',
        certificationsAndQualityStandards: '',
        manufacturingLocations: '',
        esgCompliance: '',
      };
    },
    toggleProvidedParams(state, action) {
      state.providedParams = action.payload;
    },
  },
});

export const {
  setSearchFieldValue,
  setSearchedResults,
  resetSupplierSearch,
  setSearchFieldsDropDownValues,
  toggleProvidedParams,
} = supplierSearchSlice.actions;
export default supplierSearchSlice.reducer;
