import React, { SVGProps } from 'react';

const ConversationIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    fill={props.color || '#fff'}
    className={props.className || ''}
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='m12.313 11.22.26 2.107a.664.664 0 0 1-1 .653l-2.306-1.373c-.16-.094-.2-.294-.114-.454a4.21 4.21 0 0 0 .514-2c0-2.44-2.094-4.426-4.667-4.426-.527 0-1.04.08-1.52.24a.337.337 0 0 1-.427-.4C3.66 3.14 5.993 1.333 8.78 1.333c3.253 0 5.887 2.46 5.887 5.494 0 1.8-.927 3.393-2.354 4.393Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M8.667 10.153a3.24 3.24 0 0 1-.787 2.107c-.66.8-1.707 1.313-2.88 1.313l-1.74 1.034c-.293.18-.667-.067-.627-.407l.167-1.313c-.893-.62-1.467-1.614-1.467-2.734 0-1.173.627-2.206 1.587-2.82A3.789 3.789 0 0 1 5 6.727c2.027 0 3.667 1.533 3.667 3.426Z'
    />
  </svg>
);

export default ConversationIcon;
