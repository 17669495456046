// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonOutlined_button__x7lNz{\n    text-transform: initial !important;\n    border-color:  #504FE9 !important;\n    color: #504FE9 !important;\n  }", "",{"version":3,"sources":["webpack://./src/components/ButtonOutlined/ButtonOutlined.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,iCAAiC;IACjC,yBAAyB;EAC3B","sourcesContent":[".button{\n    text-transform: initial !important;\n    border-color:  #504FE9 !important;\n    color: #504FE9 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ButtonOutlined_button__x7lNz"
};
export default ___CSS_LOADER_EXPORT___;
