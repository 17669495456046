import React from 'react';
import { JSX } from 'react/jsx-runtime';

const EactionIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width || 20}
        height={props.height || 20}
        fill={props.color || 'rgb(64 64 139)'}
        className={props.className || ''}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_12570_46923)">
            <path d="M7.94131 10.4542C8.40444 9.94009 8.89132 9.44965 9.43757 8.88811C9.65132 9.17717 9.95412 9.4058 9.94225 9.61667C9.87327 9.93635 9.74784 10.2412 9.57177 10.517L11.8019 12.7975L17.2335 7.38712L14.9369 5.05211C14.893 5.09002 14.8383 5.13503 14.7873 5.1836C14.2814 5.66814 14.0665 5.66932 13.5808 5.1836L12.4645 4.07474C11.9088 3.52505 11.9076 3.36395 12.4479 2.7799C12.4767 2.74948 12.5011 2.71519 12.5203 2.67802L10.2641 0.395142L4.80158 5.83755L7.07446 8.10267C7.87484 7.45465 8.08501 7.46055 8.6182 8.20571C8.12063 8.68906 7.62187 9.18902 7.10175 9.66526C7.02812 9.73279 6.84289 9.71619 6.71939 9.68894C6.42247 9.61003 6.10974 9.61239 5.81405 9.69578C5.51836 9.77916 5.25063 9.9405 5.03906 10.1628C4.49756 10.7054 3.95528 11.246 3.41219 11.7847L-0.0078125 15.1693L2.34225 17.5256C4.06057 15.8291 5.81215 14.1149 7.54115 12.3781C7.72833 12.1806 7.85942 11.9369 7.92098 11.6721C7.98255 11.4073 7.97234 11.1308 7.89145 10.8712C7.87137 10.8019 7.86545 10.7293 7.87402 10.6577C7.88258 10.5861 7.90545 10.5169 7.94131 10.4542ZM15.0164 6.63603L15.6387 7.33145L11.7473 11.1745L11.1084 10.5147L15.0164 6.63603ZM13.3041 6.50928L10.9386 8.8573L8.66926 6.62772L11.0538 4.23587L13.3041 6.50928ZM7.03884 6.52942L6.33582 5.82572L10.2747 1.90918L10.9196 2.6555L7.03884 6.52942ZM6.62795 11.742C5.22116 13.1549 3.80884 14.5627 2.39096 15.9654C2.36671 15.9807 2.33966 15.9912 2.31138 15.9962L1.51693 15.2404C2.59518 14.1741 3.65447 13.1328 4.7054 12.0891C5.07709 11.7207 5.44281 11.3463 5.81687 10.9802C5.86555 10.9191 5.92635 10.8687 5.99546 10.832C6.06457 10.7954 6.1405 10.7734 6.21852 10.7674C6.29654 10.7614 6.37497 10.7715 6.44889 10.7972C6.52281 10.8228 6.59064 10.8633 6.64814 10.9163C6.70654 10.9684 6.75289 11.0326 6.784 11.1044C6.81511 11.1762 6.83024 11.2538 6.82832 11.332C6.8264 11.4102 6.80747 11.487 6.77287 11.5572C6.73828 11.6273 6.68885 11.6892 6.62795 11.7385V11.742Z"
                fill={props.color || 'rgb(64 64 139)'} />
            <path d="M17.9227 17.2934C17.9227 17.1027 17.9227 16.9499 17.9227 16.7982C17.9301 16.5743 17.8925 16.3511 17.8121 16.1419C17.7317 15.9327 17.6101 15.7417 17.4545 15.5801C17.2988 15.4186 17.1124 15.2898 16.906 15.2013C16.6997 15.1128 16.4776 15.0665 16.253 15.065C14.7362 15.0366 13.2194 15.0366 11.7025 15.065C11.485 15.0649 11.2695 15.1078 11.0686 15.1913C10.8678 15.2748 10.6855 15.3972 10.5324 15.5514C10.3794 15.7057 10.2585 15.8887 10.1768 16.0899C10.0951 16.2911 10.0543 16.5065 10.0567 16.7236C10.0472 16.9025 10.0376 17.079 10.0258 17.3029H8.95703V18.3952H19.0021V17.291L17.9227 17.2934ZM16.7554 17.278H11.2169C11.0744 16.4014 11.2608 16.1798 12.0956 16.181C13.3734 16.181 14.6511 16.181 15.9288 16.1881C16.7233 16.1905 16.9062 16.4274 16.7554 17.278Z"
                fill={props.color || 'rgb(64 64 139)'} />
        </g>
        <defs>
            <clipPath id="clip0_12570_46923">
                <rect width="19" height="18" fill={props.color || 'rgb(64 64 139)'} transform="translate(0 0.397522)" />
            </clipPath>
        </defs>
    </svg>
);

export default EactionIcon;
