import { createSlice } from '@reduxjs/toolkit';
import { Interface } from 'readline';

export enum PermissionType {
  STAGE1 = 'RFI Creation',
  STAGE2 = 'RFI Supplier List',
  STAGE3 = 'RFQ Creation',
  STAGE4 = 'RFQ Suppliers List',
  STAGE5 = 'RFQ Round 1 Selection',
  STAGE6 = 'RFQ Round 2 Selection',
  STAGE7 = 'RFQ Round 3 Selection',
  STAGE8 = 'Final Negotiations',
  STAGE9 = 'Final Award Decisions',
}
interface ApprovalState {
  isApprover: boolean;
  isInApproval: boolean;
  selectedTeamMembers: any[];
  stage: string;
  requestApprovalType: string;
  requestedSuppliers: any[];
  rfqApprovalRound: number | null | undefined;
  selectedFolderData: any[],
  selectedFileData: any[],
  filePermissions: any,
}

const initialState: ApprovalState = {
  isApprover: false,
  isInApproval: false,
  selectedTeamMembers: [],
  stage: '0',
  requestApprovalType: 'RFI Creation',
  requestedSuppliers: [],
  rfqApprovalRound: null,
  selectedFolderData: [],
  selectedFileData: [],
  filePermissions: '66c300ff8c99e6e93e8f13c1',
};


const approverSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
    updateIsApprover(state, action) {
      state.isApprover = action.payload;
    },
    updateIsInApproval(state, action) {
      state.isInApproval = action.payload;
    },
    updateSelectedTeamMembers(state, action) {
      state.selectedTeamMembers = action.payload;
    },
    updateStaging(state, action) {
      state.stage = action.payload;
    },
    updateRequestApprovalType(state, action) {
      state.requestApprovalType = action.payload;
    },
    updateRequestedSuppliers(state, action) {
      state.requestedSuppliers = action.payload;
    },
    updateRfqApprovalRound(state, action) {
      state.rfqApprovalRound = action.payload;
    },
    resetApprovalState(state) {
      state.isApprover = false;
      state.isInApproval = false;
      state.selectedTeamMembers = [];
      state.stage = '0';
      state.requestApprovalType = 'RFI Creation';
      state.requestedSuppliers = [];
    },
    updateSelectedFileData(state, action) {
      state.selectedFileData = action.payload;
    },
    updateSelectedFolderData(state, action) {
      state.selectedFolderData = action.payload;
    },
    updateFilePermissions(state, action) {
      state.filePermissions = action.payload;
    },

  },
});

export const { updateIsApprover, updateIsInApproval,updateRfqApprovalRound, updateSelectedTeamMembers,resetApprovalState, updateStaging, updateRequestApprovalType,updateRequestedSuppliers,updateSelectedFileData,updateSelectedFolderData,updateFilePermissions } = approverSlice.actions;
export default approverSlice.reducer;
