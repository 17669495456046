import { Button } from '@mui/material';
import classes from './ButtonOutlined.module.css'

const ButtonOutlined = (props: any) => {

    return (
        <Button
        {...props}
            variant="outlined"
            style={props.style}
            className={`${classes.button}
           ${props.className}`}
            onClick={props.onClick}>{props.children}</Button>
    )
}

export default ButtonOutlined