import classes from './PasswordPolicy.module.css'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';



const PasswordPolicy = (props: any) => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} className={classes.policyContainer} style={props.style}>
            <Grid item xs={12}>
                <div
                    style={{
                        width: '1rem',
                        height: '1rem',
                        backgroundColor: '#fff',
                        right: '-0.6rem',
                        rotate: '45deg',
                        borderTop: '1px solid #d3d3d3',
                        borderRight: '1px solid #d3d3d3',
                        top: '7.5rem',
                        position: 'absolute',
                    }}
                ></div>
                <p className={classes.policyText}> {t('Not matching the password Policy. Must have')}</p>
                <hr />
                <p className={classes.policyText}><TaskAltOutlinedIcon sx={{ color: (!props.isMinlength ? "red" : "green") }} /> &nbsp; {t('atleast 8 characters')} </p>
                <p className={classes.policyText}><TaskAltOutlinedIcon sx={{ color: (!props.hasSpecialCharacter? "red" : "green") }} /> &nbsp; {t("a special character")}</p>
            </Grid>
        </Grid>
    )
}

export default PasswordPolicy