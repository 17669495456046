import React, { SVGProps } from 'react';

const UserManagement: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.667 2.667V0H0v12h13.333V2.667H6.667Zm-4 8H1.333V9.333h1.334v1.334Zm0-2.667H1.333V6.667h1.334V8Zm0-2.667H1.333V4h1.334v1.333Zm0-2.666H1.333V1.333h1.334v1.334Zm2.666 8H4V9.333h1.333v1.334Zm0-2.667H4V6.667h1.333V8Zm0-2.667H4V4h1.333v1.333Zm0-2.666H4V1.333h1.333v1.334Zm6.667 8H6.667V9.333H8V8H6.667V6.667H8V5.333H6.667V4H12v6.667Zm-1.333-5.334H9.333v1.334h1.334V5.333Zm0 2.667H9.333v1.333h1.334V8Z"
    />
  </svg>
  );
};

export default UserManagement;
