import React, { SVGProps } from 'react';

const MyProjectIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 20}
    height={props.height || 20}
    fill={props.color || '#fff'}
    className={props.className || ''}
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='M11.626 9.863H9.998A2.421 2.421 0 0 1 7.576 7.44V5.813a.562.562 0 0 0-.563-.563H4.636C2.903 5.25 1.5 6.375 1.5 8.385v4.98c0 2.01 1.402 3.135 3.135 3.135h4.417c1.733 0 3.135-1.125 3.135-3.135v-2.94a.562.562 0 0 0-.562-.562Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M13.366 1.5H8.948c-1.695 0-3.067 1.08-3.127 3.007.045 0 .082-.007.127-.007h4.418c1.732 0 3.135 1.125 3.135 3.135v4.987c0 .045-.008.083-.008.12 1.673-.052 3.008-1.162 3.008-3.12V4.635c0-2.01-1.403-3.135-3.135-3.135Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M8.984 5.362c-.232-.232-.63-.075-.63.248v1.965c0 .825.698 1.5 1.553 1.5.532.007 1.274.007 1.912.007.322 0 .487-.375.262-.6-.817-.817-2.272-2.28-3.097-3.12Z'
    />
  </svg>
);

export default MyProjectIcon;
