// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".policy_contentOpacity__X8oG- {\n    opacity: 0.75;\n}\n\n.policy_contentTitle__STLKi {\n    font-size: 1.125rem;\n    line-height: 1.75rem;\n}\n\n.policy_link__gT2wq{\n    font-size: 1.125rem;\n    font-weight: bold;\n    text-decoration: underline;\n    cursor: pointer;\n    color: black;\n}", "",{"version":3,"sources":["webpack://./src/components/policyDocs/policy.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,0BAA0B;IAC1B,eAAe;IACf,YAAY;AAChB","sourcesContent":[".contentOpacity {\n    opacity: 0.75;\n}\n\n.contentTitle {\n    font-size: 1.125rem;\n    line-height: 1.75rem;\n}\n\n.link{\n    font-size: 1.125rem;\n    font-weight: bold;\n    text-decoration: underline;\n    cursor: pointer;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentOpacity": "policy_contentOpacity__X8oG-",
	"contentTitle": "policy_contentTitle__STLKi",
	"link": "policy_link__gT2wq"
};
export default ___CSS_LOADER_EXPORT___;
