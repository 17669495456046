// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_loader__jRPWA {\n  width: 5rem;\n}\n\n.Spinner_spinnerContainer__4UxXC {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  z-index: 9999;\n  opacity: 0.5;\n  top: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Loader/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,eAAe;EACf,aAAa;EACb,YAAY;EACZ,MAAM;AACR","sourcesContent":[".loader {\n  width: 5rem;\n}\n\n.spinnerContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  z-index: 9999;\n  opacity: 0.5;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Spinner_loader__jRPWA",
	"spinnerContainer": "Spinner_spinnerContainer__4UxXC"
};
export default ___CSS_LOADER_EXPORT___;
