import en from './english';
import pt from './portuguese';
import sp from './spanish';
import fh from './french';
import ja from './japanese.json'
import zhHans from './chinese.json'
import de from './german.json'


export {
  en, pt, sp, fh, ja, zhHans, de
};
