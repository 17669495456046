import classes from './policy.module.css';

const CookiePolicy = () => {
  return (
    <div
      style={{ minWidth: "100%" }}
    >
      <div>
        <h1 className={classes.contentTitle}>
          Cookie Information
        </h1>
        <p>
          Our Sites use Cookies for various purposes, including to ensure that
          we give our visitors the best possible experience by providing you
          with personalized content, remembering your marketing preferences, and
          helping you to obtain the right information on our Sites.
        </p>
        <h1 className={classes.contentTitle}>
          What are Cookies?
        </h1>
        <p>
          Cookies are small files—usually consisting of letters and
          numbers—placed on your computer, tablet, phone, or similar device,
          when you use that device to visit a web site. Cookies are widely used
          by website owners to make their web sites operate, work more
          efficiently, and provide analytic information. Cookies may be placed
          on your device by Spendworkz and third parties.
        </p>
        <h1 className={classes.contentTitle}>
          Use of Cookies and Similar Technologies
        </h1>
        <p>
          This Notice describes how Spendworkz, our vendors, and other third
          parties on our behalf use Cookies and Similar Technologies (such as
          pixel tags, web beacons, clear GIFs, JavaScript, and local storage)
          ("Cookies") in connection with communications sent by Spendworkz or on
          our behalf and the websites (including mobile websites and apps) owned
          and controlled by Spendworkz (the “Sites”). Listed below are the
          different categories of Cookies that Spendworkz and vendors on our
          behalf may use on our Sites.
        </p>

        <h1 className={classes.contentTitle}>Strictly Necessary Cookies. </h1>
        <p>
          These technologies are necessary for the Spendworkz experience to
          function and cannot be switched off in our systems. The technology is
          usually only set in response to actions made by the device owner which
          amount to a request for services, such as setting privacy preferences,
          logging in, filling in forms, maintaining secure login areas,
          maintaining state across pages (remembering items in a shopping
          basket), and server load balancing. The device owner can set their
          preference to block or alert Spendworkz about these technologies, but
          some parts of the Spendworkz experience will not work. These
          technologies do not store any personally identifiable information.
        </p>

        <h1 className={classes.contentTitle}>  Analytics Cookies.</h1>

        <p >
          These technologies allow Spendworkz to count device visits and traffic
          sources, so Spendworkz can measure and improve the performance of our
          experiences. The technology helps Spendworkz to know which experiences
          are the most and least popular and see how device owners interact with
          the experience. All information these technologies collect is
          aggregated. If the device owner does not allow these technologies,
          then Spendworkz will not know when the device owner visited or how the
          device owner interacted with our experiences.
        </p>
      </div>

      <h1 className={classes.contentTitle}> Functional Cookies.</h1>
      <p>
        These technologies enable the Spendworkz experience to provide enhanced
        functionality and personalization. The technology may be set by
        Spendworkz or by third-party providers whose services Spendworkz have
        added to our experiences. If the device owner does not allow these
        technologies, then some or all of these services may not function
        properly for the device owner.
      </p>
      <h1 className={classes.contentTitle}>  Ad Targeting Cookies. </h1>
      <p>
        These technologies may be set through our Spendworkz experience or by
        our advertising partners. The technology may be used to build a
        profile of the device owner’s interests and show the device owner
        relevant advertisements on other experiences. The technology does not
        store directly personal information on the device, but the technology
        is based on uniquely identifying the device. If the device owner does
        not allow these technologies, then the device owner will experience
        less targeted advertising.
      </p>
      <h1 className={classes.contentTitle}>
        How Long do Cookies Stay on My Device?
      </h1>
      <p>
        Some Cookies operate from the time you visit a Site until the end of
        that particular browsing session. These "session" Cookies expire and
        are automatically deleted when you close your Internet browser. Some
        Cookies stay on your device between browsing sessions—these
        "persistent" Cookies do not expire when you close your browser. The
        length of time a persistent Cookie stays on your device varies from
        Cookie to Cookie.
      </p>
      <h1 className={classes.contentTitle}>
        How do I Manage Cookies?
      </h1>
      <p >
        Some Cookies operate from the time you visit a Site until the end of
        that particular browsing session. These "session" Cookies expire and
        are automatically deleted when you close your Internet browser. Some
        Cookies stay on your device between browsing sessions—these
        "persistent" Cookies do not expire when you close your browser. The
        length of time a persistent Cookie stays on your device varies from
        Cookie to Cookie.
      </p>

    </div>
  )
}

export default CookiePolicy;