import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const initialSelectedFilters = [
  { category: [] },
  { subCategory: [] },
  { program: [] },
  { legalBusinessEntity: [] },
  { partNumber: [] },
  { contractExpirationDate: [] },
  { Supplier: [] },
];

const initialState = {
  showCubeData: false,
  gridView: true,
  spendCubeList: [],
  cubeAnalyticsGridData: null,
  cubeAnalysisData: [],
  advancedFilterColumns: [],
  selectedFilter: initialSelectedFilters,
  appliedFilter: initialSelectedFilters,
  filterPrimaryColumn: null,
  showArchivedList:
    localStorage.getItem('showArchivedList') === 'true' ? true : false, // on click breadCrumb its reloaded whole app so having state in localstore
};

const hasArrayLengthOne = (arr: any) => {
  return arr.some((obj: any) => {
    for (const key in obj) {
      const value = obj[key];
      return Array.isArray(value) && value.length > 0;
    }
    return false;
  });
};

const spendCubeSlice = createSlice({
  name: 'spendCube',
  initialState,
  reducers: {
    toggleSpendCubeList(state, action) {},
    setSpendCubeList(state, action) {
      state.spendCubeList = action.payload;
    },
    setAdvancedFilterColumnList(state, action) {
      state.advancedFilterColumns = action.payload;
    },
    setSelectedFilterList(state, action) {
      state.selectedFilter = _.cloneDeep(action.payload);
    },
    resetAdvancedFilterColumnList(state, action) {
      state.advancedFilterColumns = action.payload;
    },
    resetSelectedFilter(state, action) {
      state.selectedFilter = initialSelectedFilters;
      state.appliedFilter = initialSelectedFilters;
      state.filterPrimaryColumn = null;
      state.cubeAnalyticsGridData = null;
      state.cubeAnalysisData = [];
    },
    setAnalyticGridData(state, action) {
      state.cubeAnalyticsGridData = action.payload;
    },
    setFilterPrimaryColumn(state, action) {
      state.filterPrimaryColumn = action.payload;
    },
    setShowArchivedList(state, action) {
      const showArchivedList =
        state.showArchivedList.toString() === 'true' ? 'false' : 'true';
      localStorage.setItem('showArchivedList', showArchivedList);
      state.showArchivedList = !state.showArchivedList;
    },
    clearShowArchivedList(state, action) {
      localStorage.setItem('showArchivedList', 'false');
      state.showArchivedList = false;
    },
    setAppliedFilter(state, action) {
      state.appliedFilter = action.payload;
    },
    updateAppliedFilterOnSelectedFilter(state, action) {
      state.selectedFilter = state.appliedFilter;
      if (!hasArrayLengthOne(state.selectedFilter)) {
        state.filterPrimaryColumn = null;
      }
    },
  },
});

export const {
  toggleSpendCubeList,
  setSpendCubeList,
  setAdvancedFilterColumnList,
  resetAdvancedFilterColumnList,
  setSelectedFilterList,
  resetSelectedFilter,
  setAnalyticGridData,
  setFilterPrimaryColumn,
  setShowArchivedList,
  clearShowArchivedList,
  setAppliedFilter,
  updateAppliedFilterOnSelectedFilter,
} = spendCubeSlice.actions;
export default spendCubeSlice.reducer;
