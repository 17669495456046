import Avatar from '@mui/material/Avatar'
import { useState } from 'react'
const customAvatar = (props: any) => {

  function stringToColor(string: string) {
    let hash = 0;
    let i;


    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }


    return color;
  }


  function stringAvatar(name: string) {

    const avatarColor = {
      bgcolor: 'lavender !important',
      color: "#0B1B49 !important"
    }

    if (!name) {
      return ''
    }
    const NAME = name?.trim()
    if (!NAME || NAME === "") {
      return {
        sx: { avatarColor }
      }
    }

    else {
      if (NAME.includes(' ')) {
        return {
          sx: { avatarColor },
          children: (`${NAME.split(' ')[0][0]}${NAME.split(' ')[1][0]}`).toUpperCase(),
        }
      }
      else {
        return {
          sx: { avatarColor },
          children: (`${NAME.charAt(0)}`).toUpperCase(),
        }
      }
    }
  }

  return (
    <>
      <Avatar {...stringAvatar(props.children)} className={props.className} sx={props.sx} />
    </>
  )
}

export default customAvatar