import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import CircularIndeterminate from './components/Spinner/Circular';
import './i18n';
import { ReactQueryProvider } from './Provider';
import { store } from './store';
import { LicenseInfo } from '@mui/x-license-pro';
import ErrorBoundary from 'components/errorBoundry/ErrorBoundry';

const key =
  '6ee67ce6afc6b8aa1cf0349d334d12b8Tz05MzAzNyxFPTE3NTA4MzA1ODgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
LicenseInfo.setLicenseKey(key);
const root = document.getElementById('root') as HTMLElement;

const loadingMarkup = (
  <div className='py-4 text-center'>
    <CircularIndeterminate />
  </div>
); 

function hideLogs() {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => { };
    console.warn = () => { };
    console.error = () => { };
  }
}

hideLogs();

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <ReactQueryProvider>
            <App />
          </ReactQueryProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  </Suspense>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
