import { createSlice } from '@reduxjs/toolkit';

const initialState: number = 0;

const rfiSlice = createSlice({
  name: 'rfi',
  initialState,
  reducers: {
    updateSideNavigationIndex(state, action) {
      state = action.payload;
    },
  },
});

export const { updateSideNavigationIndex } = rfiSlice.actions;
export default rfiSlice.reducer;

