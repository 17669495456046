import classes from './policy.module.css';

const PrivacyPolicy = () => {
    return (
        <div >
            <h1 className={classes.contentTitle}>
                Privacy Information
            </h1>
            <div >
                <p >
                    Spendworkz’s business brings technology to businesses, consumers, a nd
                    society in a way that makes amazing experiences possible but doesn’t
                    come at the expense of your privacy. We believe in innovation and
                    performance that supports your privacy rights and in technology
                    designed with those rights in mind. This Privacy Notice describes how
                    we use your personal information and respect your privacy rights. This
                    Privacy Notice explains how our products, services and technologies
                    use your personal information.
                    <br />
                    <br /> Spendworkz collects information as part of its business
                    operations, to provide services, to respond to requests and offer
                    customer support, to fulfill legal and contractual obligations and to
                    build its innovative products. You provide some of this data directly,
                    such as when you order an Spendworkz product, contact customer support,
                    or register for an Spendworkz event or publication. We also obtain data
                    from third parties.
                    <br />
                    <br /> We collect information about how you interact with our website
                    and some Spendworkz Services through the use of cookies, pixel tags,
                    and similar technologies. Please view our{' '}
                    <a href="#cookies-policy" className={classes.link}>
                        cookie policy
                    </a>
                </p>

                
                <span >
                    for more information on our use of cookies and similar technologies,
                    how you can manage cookies and how we respond to Do Not Track signals.{' '}
                    <br />
                    <br />
                    We use information that we collect for lawful purposes associated with
                    the growth, maintenance and management of our business while also
                    respecting your privacy. These uses include our internal operations
                    and administration, communicating with you and fulfilling your
                    requests for Services, for advertising and marketing, to monitor and
                    maintain functionality and security of Services and to improve,
                    develop, enhance and otherwise provide the Services.
                    <br />
                    <br /> We work through our affiliates to provide some of the Services.
                    We also work with authorized suppliers and business partners. When we
                    share your personal information with these companies, we put in place
                    appropriate measures to limit the use of your information only for
                    legal and authorized purposes that are consistent with this Privacy
                    Notice, as well as appropriate confidentiality and security measures.
                    For example, we rely on affiliates and partners, such as our
                    resellers, to complete a purchase transaction or provide Services,
                    such as support, that you have requested. We also share information
                    with third parties for advertising and marketing; when required by law
                    or to respond to legal process; to protect our customers; to protect
                    lives; to maintain the security of Services; and to protect our legal
                    rights. <br />
                    <br />
                    Spendworkz respects your rights in how your personal information is
                    used and shared. You may request access or corrections to your
                    personal data and make choices about the kinds of marketing materials
                    you receive (or choose not to receive marketing from Spendworkz at
                    all). If you are in Europe, you may have additional rights under the
                    GDPR. Additional choices and rights may be available to you depending
                    on which Services you use. <br />
                    <br />
                    If you have questions or concerns about Intel’s privacy practices or
                    to exercise any of your rights and choices as described in this
                    notice, you can select the appropriate link below: To exercise your
                    privacy rights such as accessing, deleting or changing your personal
                    information as described in this Notice under Your Choices and Rights,
                    please submit your request here {''}
                </span>
                <a
                    className='font-bold italic cursor-pointer'
                    href='mailto:sanjeev@spendworkz.com'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    sanjeev@spendworkz.com
                </a>
            </div>
        </div>
    );

}

export default PrivacyPolicy;