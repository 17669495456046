import React, { SVGProps } from 'react';

const UserManagement: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M.713 15v-1.762c0-.438.112-.835.337-1.191.225-.356.538-.622.938-.797.912-.4 1.734-.688 2.465-.863a9.674 9.674 0 0 1 2.26-.262c.775 0 1.525.088 2.25.262.725.175 1.543.463 2.456.863a2.14 2.14 0 0 1 1.294 1.988V15h-12Zm13.125 0v-1.762c0-.788-.2-1.435-.6-1.941-.4-.506-.925-.916-1.575-1.228.862.1 1.675.247 2.437.44.763.194 1.381.416 1.856.666.413.237.738.531.975.881.238.35.357.744.357 1.182V15h-3.45ZM6.713 8.981c-.825 0-1.5-.262-2.025-.787-.525-.525-.788-1.2-.788-2.025s.263-1.5.788-2.025c.525-.525 1.2-.788 2.025-.788s1.5.263 2.025.788c.525.525.787 1.2.787 2.025s-.262 1.5-.787 2.025c-.525.525-1.2.787-2.025.787Zm6.75-2.812c0 .825-.263 1.5-.788 2.025-.525.525-1.2.787-2.025.787-.138 0-.29-.01-.46-.028a1.983 1.983 0 0 1-.459-.103c.3-.312.528-.697.685-1.153.156-.456.234-.966.234-1.528 0-.563-.078-1.06-.234-1.49a3.984 3.984 0 0 0-.685-1.191 3.958 3.958 0 0 1 .919-.132c.825 0 1.5.263 2.025.788.525.525.787 1.2.787 2.025ZM1.838 13.875h9.75v-.637c0-.2-.06-.394-.179-.582a.98.98 0 0 0-.44-.394c-.9-.4-1.656-.668-2.269-.806a9.067 9.067 0 0 0-1.987-.206c-.713 0-1.379.069-1.997.206-.62.138-1.378.407-2.278.806a.93.93 0 0 0-.432.394 1.115 1.115 0 0 0-.168.582v.637Zm4.875-6.019c.487 0 .89-.16 1.209-.478.319-.319.478-.722.478-1.21 0-.487-.16-.89-.478-1.209-.319-.318-.722-.478-1.21-.478-.487 0-.89.16-1.209.478-.319.32-.478.722-.478 1.21 0 .487.16.89.478 1.21.319.318.722.477 1.21.477Z"
    />
  </svg>
  );
};

export default UserManagement;
