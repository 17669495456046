import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import dashboardSlice from "../features/dashboardSlice";
import spendCubeSlice from "../features/spendCubeSlice";
import spendClassificationSlice from "../features/spendClassificationSlice";
import eauctionSlice from "../features/eauctionSlice";
import rfiSlice from "../features/rfiSice";
import userSlice from "../features/userSlice";
import loaderSlice from "../features/loaderSlice";
import editUserSlice from "features/editUserSlice";
import approvalSlice from "features/approvalSlice";
import awardPlannerSlice from "features/awardPlannerSlice";
import supplierSearchSlice from "features/supplierSearchSlice";
import languageSlice from "features/languageSlice";
import localstorageSlice from "features/localstorageSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    spendCube: spendCubeSlice,
    spendClassification: spendClassificationSlice,
    eauction: eauctionSlice,
    rfi: rfiSlice,
    user: userSlice,
    loader: loaderSlice,
    editUser: editUserSlice,
    approval: approvalSlice,
    awardPlanner: awardPlannerSlice,
    supplierSearch: supplierSearchSlice,
    languageChange: languageSlice,
    localstorageValue:localstorageSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
