import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const initialSelectedFilters = [ 
];

const initialState = {
  showeAuctionDetails:false,
  eAuctionData:null,
  eAuctionSupplierData:null,
};


const eauctionSlice = createSlice({
  name: 'eauction',
  initialState,
  reducers: {
    setShoweAuctionDetails(state, action) {
      state.showeAuctionDetails = action.payload;
    },
    seteAuctionData(state, action) {
      state.eAuctionData = action.payload;
    },
    setSupllierAuctionData(state, action) {
      state.eAuctionSupplierData = action.payload;
    },
  },
});

export const {
  setShoweAuctionDetails,
  seteAuctionData,
  setSupllierAuctionData,
} = eauctionSlice.actions;
export default eauctionSlice.reducer;
