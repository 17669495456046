import { createSlice } from "@reduxjs/toolkit";

interface APIState {
  language: string;
}

const initialState: APIState = {
  language: "en",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    updateLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { updateLanguage } = languageSlice.actions;
export default languageSlice.reducer;
