import AppRouter from './routes';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import Loader from '../src/components/Loader/Spinner';

const theme = createTheme({
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderColor: '#504fe9',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#504fe9', // Set your desired focus outline color here
            },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#504fe9', // Replace 'gray' with your desired default color value
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiAutocomplete-input': {
            borderColor: '#504fe9', // Set your desired focus outline color here
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#504fe9', // Replace 'gray' with your desired default color value
          },
        },
      },
    },
  },
});
console.log('env', process.env.NODE_ENV);
console.log('env', process.env.REACT_APP_BASE_URL);
export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Loader />
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  );
}
