import React, { SVGProps } from 'react';

const WarningIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={props.width || '20'}
    height={props.height || '20'}
    fill='transparent'
    {...props}
  >
    <path
      fill={props.color || '#E38077'}
      d='M0 20.583h23.833L11.917 0 0 20.583Zm13-3.25h-2.167v-2.166H13v2.166ZM13 13h-2.167V8.667H13V13Z'
      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default WarningIcon;
