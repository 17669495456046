import { lazy } from 'react';

// project imports
import Loadable from '../components/uiLoadable/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const VerifyUser = Loadable(lazy(() => import('../components/verifyUser/verifyUser')));


const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/ForgotPassword'))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const VerifyUserRoutes = {
  path: '/verify/:id',
  element: <VerifyUser />,
  
};

export default VerifyUserRoutes;
