// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PasswordPolicy_policyContainer__GCkP8 {\n    flex-direction: column;\n    top: -90px;\n    width: 300px;\n    border: 1px solid #D9D9D9;\n    background-color: #fff;\n    font-size: 70;\n    border-radius: 5px;\n    position: absolute;\n    z-index: 2;\n    left: -103%;\n\n}\n\n.PasswordPolicy_policyText__daHXS {\n    text-align: left;\n    display: flex;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/registration/passwordPolicy/PasswordPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,WAAW;;AAEf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".policyContainer {\n    flex-direction: column;\n    top: -90px;\n    width: 300px;\n    border: 1px solid #D9D9D9;\n    background-color: #fff;\n    font-size: 70;\n    border-radius: 5px;\n    position: absolute;\n    z-index: 2;\n    left: -103%;\n\n}\n\n.policyText {\n    text-align: left;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyContainer": "PasswordPolicy_policyContainer__GCkP8",
	"policyText": "PasswordPolicy_policyText__daHXS"
};
export default ___CSS_LOADER_EXPORT___;
