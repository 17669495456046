import React, { SVGProps } from 'react';

const RejectIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 54}
    height={props.height || 54}
    viewBox="0 0 54 54"
    fill="none"
    style={{ marginBottom: '120px' }} // Adjust the marginTop value as needed
    {...props}
  >
    <g clipPath="url(#clip0_9108_52879)">
      <circle cx="27" cy="33" r="21" fill="#FF2626" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4091 40.5863C20.8594 41.1379 19.9634 41.1379 19.4137 40.5863C18.8621 40.0366 18.8621 39.1406 19.4137 38.5909L32.5909 25.4137C33.1406 24.8621 34.0366 24.8621 34.5863 25.4137C35.1379 25.9634 35.1379 26.8594 34.5863 27.4091L21.4091 40.5863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5863 38.5909C35.1379 39.1406 35.1379 40.0366 34.5863 40.5863C34.0366 41.1379 33.1406 41.1379 32.5909 40.5863L19.4137 27.4091C18.8621 26.8594 18.8621 25.9634 19.4137 25.4137C19.9634 24.8621 20.8594 24.8621 21.4091 25.4137L34.5863 38.5909Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9108_52879">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RejectIcon;
