import React, { SVGProps } from 'react';

const SuccessIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={props.width || 20}
    height={props.height || 20}
    fill='transparent'
    {...props}
  >
    <path
      fill={props.color || '#6bb591'}
      d='m23.46 10.488-1.632-1.895c-.311-.36-.563-1.032-.563-1.512v-2.04a2.325 2.325 0 0 0-2.316-2.315h-2.04c-.467 0-1.15-.252-1.51-.564L13.502.531c-.828-.708-2.184-.708-3.024 0L8.596 2.174c-.36.3-1.044.552-1.512.552H5.01a2.325 2.325 0 0 0-2.316 2.316v2.051c0 .468-.252 1.14-.552 1.5L.521 10.5c-.695.828-.695 2.172 0 3l1.62 1.907c.3.36.552 1.032.552 1.5v2.051a2.325 2.325 0 0 0 2.316 2.316h2.075c.468 0 1.152.252 1.512.563l1.895 1.632c.828.708 2.184.708 3.024 0l1.895-1.632c.36-.311 1.032-.563 1.512-.563h2.04a2.325 2.325 0 0 0 2.315-2.316v-2.04c0-.467.252-1.15.563-1.51l1.632-1.896c.696-.828.696-2.196-.012-3.024Zm-6.478-.755-5.795 5.794a.899.899 0 0 1-1.271 0l-2.904-2.903a.905.905 0 0 1 0-1.272.905.905 0 0 1 1.272 0l2.267 2.268 5.16-5.16a.905.905 0 0 1 1.27 0 .905.905 0 0 1 0 1.273Z'
      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default SuccessIcon;
