import React, { SVGProps } from 'react';

const CubeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 20}
    height={props.height || 20}
    fill={props.color || '#fff'}
    className={props.className || ''}
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='M16.5 7.312a.567.567 0 0 1-.562-.562v-1.5c0-1.935-1.252-3.188-3.187-3.188h-7.5c-1.935 0-3.188 1.253-3.188 3.188v1.5a.567.567 0 0 1-.562.562.567.567 0 0 1-.563-.562v-1.5C.938 2.67 2.671.937 5.251.937h7.5c2.58 0 4.312 1.733 4.312 4.313v1.5a.567.567 0 0 1-.562.562ZM12.75 17.063h-7.5c-2.58 0-4.312-1.733-4.312-4.313v-1.5c0-.307.255-.563.563-.563.307 0 .562.256.562.563v1.5c0 1.935 1.253 3.188 3.188 3.188h7.5c1.935 0 3.187-1.253 3.187-3.188v-1.5c0-.307.255-.563.563-.563.307 0 .562.256.562.563v1.5c0 2.58-1.732 4.313-4.312 4.313Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M12.3 6.157 9.48 4.635a1.02 1.02 0 0 0-.952 0L5.7 6.157a.65.65 0 0 0-.33.57c0 .248.128.465.33.578l2.82 1.522a.99.99 0 0 0 .48.12.99.99 0 0 0 .48-.12l2.82-1.522a.653.653 0 0 0 .33-.578.65.65 0 0 0-.33-.57ZM8.055 9.352 5.43 8.04a.65.65 0 0 0-.63.03.645.645 0 0 0-.3.547V11.1c0 .427.24.817.622 1.005l2.625 1.312a.645.645 0 0 0 .63-.03.635.635 0 0 0 .308-.547v-2.483a1.132 1.132 0 0 0-.63-1.005ZM13.192 8.07a.65.65 0 0 0-.63-.03L9.937 9.352a1.125 1.125 0 0 0-.623 1.005v2.483c0 .225.113.427.308.547a.609.609 0 0 0 .337.098.693.693 0 0 0 .293-.068l2.625-1.312c.382-.195.622-.578.622-1.005V8.617a.635.635 0 0 0-.307-.547Z'
    />
  </svg>
);

export default CubeIcon;
