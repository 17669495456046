import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rfqTemplateId: ""
};
const localStorageSlice = createSlice({
  name: 'rfq',
  initialState,
  reducers: {
    updateRFQTemplateId(state, action) {
        state.rfqTemplateId = action.payload.rfqTemplateId;
        
    },
  },
});

export const { updateRFQTemplateId } = localStorageSlice.actions;
export default localStorageSlice.reducer;

