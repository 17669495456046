export const SUCCESS = 'Success!';
export const FAILED = 'Failed!';
export const SOMETHING_WENT_WRONG = 'Something went wrong!';
export const UPDATED_SUCCESSFULLY = 'Updated successfully';
export const UPDATED_FAILED = 'Updated failed';
export const DELETED_SUCCESSFULLY = 'Deleted successfully';
export const DELETED_FAILED = 'Deleted failed';
export const CREATED_SUCCESSFULLY = 'Created successfully';
export const CREATED_FAILED = 'Created failed';
export const NON_VERIFIED_USER_LOGIN = 'Link not verified';
export const NON_VERIFIED_USER_LOGIN_MESSAGE = 'Please verify your account to access spendworkz'
export const FETCHED_SUCCESSFULLY = 'Fetched successfully';
export const FETCHED_FAILED = 'Failed to fetch';
export const LOGIN_SUCCESSFULLY = 'Login successfully';
export const LOGIN_FAILED = 'Login failed';
export const LOGOUT_SUCCESSFULLY = 'Logout successfully';
export const LOGOUT_FAILED = 'Logout failed';
export const REGISTER_SUCCESSFULLY = 'Register successfully';
export const REGISTER_FAILED = 'Register failed';
export const RESET_PASSWORD_SUCCESSFULLY = 'Reset password successfully';
export const RESET_PASSWORD_FAILED = 'Reset password failed';
export const FORGOT_PASSWORD_SUCCESSFULLY = 'Forgot password successfully';
export const FORGOT_PASSWORD_FAILED = 'Forgot password failed';
export const VERIFY_EMAIL_SUCCESSFULLY = 'Verify email successfully';
export const VERIFY_EMAIL_FAILED = 'Verify email failed';
export const VERIFY_PHONE_SUCCESSFULLY = 'Verify phone successfully';
export const VERIFY_PHONE_FAILED = 'Verify phone failed';
export const VERIFY_OTP_SUCCESSFULLY = 'Verify otp successfully';
export const VERIFY_OTP_FAILED = 'Verify otp failed';
export const VERIFY_TOKEN_SUCCESSFULLY = 'Verify token successfully';
export const VERIFY_TOKEN_FAILED = 'Verify token failed';
export const VERIFY_PASSWORD_SUCCESSFULLY = 'Verify password successfully';
export const VERIFY_PASSWORD_FAILED = 'Verify password failed';
export const VERIFY_USERNAME_SUCCESSFULLY = 'Verify username successfully';
export const VERIFY_USERNAME_FAILED = 'Verify username failed';
export const VERIFY_EMAIL_OR_PHONE_SUCCESSFULLY =
  'Verify email or phone successfully';
export const VERIFY_EMAIL_OR_PHONE_FAILED = 'Verify email or phone failed';
export const VERIFY_EMAIL_OR_USERNAME_SUCCESSFULLY =
  'Verify email or username successfully';
export const VERIFY_EMAIL_OR_USERNAME_FAILED =
  'Verify email or username failed';
export const USER_PROFILE_UPDATED_SUCCESSFULLY =
  'User profile updated successfully';
export const MUST_BE_A_VALID_BUSINESS_EMAIL_ADDRESS  =
  'Must be a valid business email address';
export const RESET_YOUR_PASSWORD_SUCCESSFULLY='You have successfully reset your password.'
export const LOGIN_NOW='Login now'
export const PASSWORD_RESET_SUCCESSFUL='Password reset successful!'
export const VERIFY_AND_RESET_YOUR_PASSWORD='We have sent a verification email. Please verify and reset your password'
export const RESEND_VERIFICATION_LINK='Resend verification link'

export const USER_PROFILE_UPDATED_FAILED = 'User profile update failed';
export const ORGANISATION_PROFILE_DETAIL_UPDATED_SUCCESSFULLY='Organization profile updated successfully'
export const ORGANISATION_PROFILE_DETAIL_UPDATED_SUCCESSFULLY_MESSAGE =
  'Organisation profile details updated successfully';
  export const ORGANISATION_PROFILE_DETAIL_GET_FAILED =
  'Failed to Organisation profile details';
export const ORGANISATION_PROFILE_DETAIL_UPDATED_FAILED =
  'Organisation profile details update failed';
  export const SUPPLIERS_ORGANISATION_PROFILE_DETAIL_UPDATED_FAILED =
  'Failed to get suppliers Organisation profile details';
export const USER_INVITED_SUCCESSFULLY = (name:string)=>
 {return `You have succesfully invited ${name}`;}
  export const USER_UPDATED_SUCCESSFULLY =
  'User updated successfully';
export const USER_UPDATED_FAILED = 'User update failed';

export const USER_INVITED_FAILED = 'User invite has been failed';

export const USER_DETAILES_UPDATED_AND_SENT_EMAIL_FAILED =
  'User details updated and email sent failed';

export const LOGGED_IN_SUCCESSFULLY = 'Logged in successfully';
export const LOGGED_IN_SUCCESS_MESSAGE =
  'You have Logged in successfully into SpendWorkz';
export const LOGGED_IN_SUCCESSFULLY_WITHOUT_EMAIL_VERIFICATION =
  'Logged in successfully without email verification';
export const LOGGED_IN_FAILED = 'Login failed';
export const LOGGED_IN_FAILED_MESSAGE = 'You have Logged in failed';
export const LOGGED_OUT_SUCCESSFULLY = 'Logged out successfully';
export const LOGGED_OUT_FAILED = 'Logged out failed';
export const RESET_LINK_SENT_SUCCESSFULLY = 'Reset link sent successfully';
export const RESET_LINK_SENT_FAILED = 'Reset link sent failed';
export const VERIFY_YOUR_EMAIL = 'Verify your email'
export const VERIFY_YOUR_EMAIL_MESSAGE = () => {
  const email = localStorage.getItem('email');
  return `We have sent a verification link to ${email}. Please verify and complete`;
};
export const SESSION_EXPIRED = 'Session Expired'
export const SESSION_EXPIRED_MESSAGE = 'Your session has expired, Please login again.'
export const FAILED_TO_GET_USERS_MESSAGE = 'Failed to get users'
export const REINVITE_USER_SUCCESS_MESSAGE = 'You have re-invited the user successfully'
export const REINVITE_USER_FAIL_MESSAGE = 'Failed to re-invite user'
export const  COMING_SOON_HEADER = 'Coming soon'
export const COMING_SOON_MESSAGE = 'Stay tuned ! We are launching soon'
export const VERIFICATION_LINK_SENT_SUCCESFULLY_MESSAGE = 'Link sent successfully !'
export const VERIFICATION_LINK_SENT_FAILED_MESSAGE = 'Failed to send link.Try after sometime'
export const USER_NOT_VERIFIED = 'User not verified'
export const LINK_EXPIRED = 'Link Expired'
export const LINK_EXPIRED_MESSAGE = 'The link you are trying to access is either expired or does not exist'
export const FAILED_TO_FETCH_PROJECTS = 'Could not fetch projects'
export const FAILED_TO_GENERATE_PROJECT_ID = 'Failed to generate project Id'
export const FAILED_TO_FETCH_USERS = 'Failed to fetch users'
export const FAILED_T0_FETCH_TEMPLATE = 'Failed to fetch the selected template'
export const RFI_CREATED_SUCCESSFULLY = 'You have successfully created RFI'
export const RFI_CREATED_FAILED  = 'Failed to create RFI'       
export const SUPPLIERS_INVITED_SUCCESSFULLY = 'You have succesfuly invited the supplier'
export const SUPPLIERS_INVITED_FAILED = 'Failed to invite the supplier'
export const LAUNCH_RFI_SUCCESFULLY= 'You have sucessfully launched RFI'
export const LAUNCH_RFI_FAILED = 'Failed to launch RFI'
export const FAILED_T0_FETCH_TEMPLATE_LIST = 'Failed to fetch the template lists'
export const LAUNCH_RFQ_SUCCESSFULLY = 'You have successfully launched RFQ';
export const FAILED_TO_UPDATE_DATA="Failed to update data"
export const PROJECt_DETAILS_UPDATED_SUCCESSFULLY="Project Details updated successfully"
export const SUPPLIERS_ADDE_SUCCESSFULLY ='You have successfully added suppliers.Please create RFQ template'
export const UNSHORTLIST_FAILED="Failed to Unshortlist"
export const SHORTLISTED_SELECTED_SUPPLIERS_SUCCESFULLY='You have succesfully shortlisted selected suppliers'
export const UNSHORTLISTED_SELECTED_SUPPLIERS_SUCCESFULLY='You have succesfully Unshortlisted selected suppliers'
export const FAILED_TO_GET_PROJECT_DETAILS="Failed to get project details"
export const FAILED_TO_FETCH_CONVERSATION_DETAILS='Failed to fetch conversation details'
export const PROJECT_ACCEPTED='The project has been accepted'
export const PROJECT_REJECTED='The project has been rejected'
export const RFQ_SUBMITTING= 'Thank you for submitting your RFQ. Buyer will review and send RFQ invitation if shortlisted'
export const SUBMITTED_SUCCESSFULLY='Submitted successfully!'
export const FAILED_TO_SHORTLIST="Failed to shortlist"
export const FAILED_TO_UNSHORTLIST="Failed to unshortlist"
export const RFI_SUBMITTING="Thank you for submitting your RFI. Buyer will review and send RFQ invitation if shortlisted"
export const RFQ_FORM_SAVED_SUCCESSFULL='RFQ form saved successfully'
export const RFQ_FORM_SAVED_FAILED='RFQ form saved failed. Please try again.'
export const DELETED_PROJECT_SUCCESFULLY="You have Succesfully deleted the project"
export const FAILED_TO_DELETE="Failed to delete"
export const UPDATE_FILTER_FAILED='Update filter failed'
export const FILE_UPLOAD_SUCESS='File Uploaded Successfully!'
export const FILE_UPLOAD_FAILED='File Upload Failed!'
export const FILE_DELETE_FAILED='File Deleting Failed!'
export const UPDATE_EXCHANGE_RATE_FAILED='Update exchange rate failed!'
export const CURRENCY_EXCHANGE_RATE_UPDATED='Currency exchange rate updated'
export const CURRENCY_EXCHANGE_RATE_UPDATE_FAILED='Currency exchange rate update failed'
export const META_DATA_DETAILS_UPDATED='The meta data details has been  updated.'
export const UPDATE_META_DATA_HAS_FAILED='Update meta data has failed.'
export const ERROR_ARCHIVED_UNARCHIVED_SPEND_CUBE='Error in Archived/Unarchived the spend cube'
export const ARCHIVED_UNARCHIVED_FAILED='Archived/Unarchived has failed!'
export const SPEND_CUBE_ARCHIVED_UNARCHIVED_SUCCESSFULLY='The spend cube Archived/Unarchived Successfully'
export const ARCHIVED_UNARCHIVED_SUCCESSFULLY='Archived/Unarchived Successfully!'
export const SPEND_CUBE_DELETED_SUCCESSFULLY='The spend cube deleted Successfully'
export const ERROR_IN_DELETING_SPEND_CUBE ='Error in deleting the spend cube'
export const SPEND_CUBE_DATA_FETCH_FAILED = 'Spend cube data fetch failed.!'
export const UPDATE_FAILED='Update Failed!'
export const EXCEED_FILE_SIZE='Please upload, Each file must be below 200MB, and the total size of all files should not exceed 1024MB'


export const FILE_FORMAT_FAILED = 'File format is not matched'

export const NO_AUTOFILL_DETAILS =
  'Please update your contact information in the organization profile to use this feature.';



