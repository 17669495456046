// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogModel_error__yMBAV{\n    background-color: #BD4334;\n} \n\n.DialogModel_success__zK7q7 {\n    background-color: #6bb591;\n}\n\n.DialogModel_confirm__HJ379 {\n    background-color: #e1c340;\n}\n\n.DialogModel_rfqNextRoundConfirm__8u4Xx {\n    background-color: #e1c340;\n}\n.DialogModel_action__g\\+CMq {\n    background-color: #e1c340;\n}\n\n.DialogModel_warning__HNRIM{\n    background-color: #BD4334;\n}\n\n.DialogModel_test__zL8YF{\n    background-color: #BD4334;\n}\n\n.DialogModel_approveInbox__WQg0G{\n    background-color: #F2BE42;\n}\n.DialogModel_rejectInbox__yfk-x{\n    background-color: #FF2626;\n}\n.DialogModel_confirmRFI__NZ3o5{\n    background-color: #e1c340;\n}\n\n.DialogModel_confirmAward__om\\+7M {\n    background-color: #e1c340;\n}", "",{"version":3,"sources":["webpack://./src/components/models/DialogModel/DialogModel.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".error{\n    background-color: #BD4334;\n} \n\n.success {\n    background-color: #6bb591;\n}\n\n.confirm {\n    background-color: #e1c340;\n}\n\n.rfqNextRoundConfirm {\n    background-color: #e1c340;\n}\n.action {\n    background-color: #e1c340;\n}\n\n.warning{\n    background-color: #BD4334;\n}\n\n.test{\n    background-color: #BD4334;\n}\n\n.approveInbox{\n    background-color: #F2BE42;\n}\n.rejectInbox{\n    background-color: #FF2626;\n}\n.confirmRFI{\n    background-color: #e1c340;\n}\n\n.confirmAward {\n    background-color: #e1c340;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "DialogModel_error__yMBAV",
	"success": "DialogModel_success__zK7q7",
	"confirm": "DialogModel_confirm__HJ379",
	"rfqNextRoundConfirm": "DialogModel_rfqNextRoundConfirm__8u4Xx",
	"action": "DialogModel_action__g+CMq",
	"warning": "DialogModel_warning__HNRIM",
	"test": "DialogModel_test__zL8YF",
	"approveInbox": "DialogModel_approveInbox__WQg0G",
	"rejectInbox": "DialogModel_rejectInbox__yfk-x",
	"confirmRFI": "DialogModel_confirmRFI__NZ3o5",
	"confirmAward": "DialogModel_confirmAward__om+7M"
};
export default ___CSS_LOADER_EXPORT___;
