import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import CustomAvatar from "../../components/avatar/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import LargeLogo from "../../assets/large_logo_white.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import dashboardLogo from "../../assets/large_logo_white.png";
import ProjectSrcIcon from "../../assets/images/my_projects_white.svg";
import ProjectHoverIcon from "../../assets/images/My projects_Black.svg";
import BarChartIcon from "@mui/icons-material/BarChart";
import cubeIcon from "../../assets/images/spend_cube_white.svg";
import cubeHoverIcon from "../../assets/images/Spend_Cube_Black.svg";
// import SupplierIcon from '../../assets/images/Suppliers_White.svg';
import SupplierHoverIcon from "../../assets/images/Suppliers_Black.svg";
import conversationIcon from "../../assets/images/Conversations_White.svg";
import conversationHoverIcon from "../../assets/images/Conversations_Black.svg";
import UserManagementHoverIcon from "../../assets/images/User management_Black.svg";
import OrganisationProfileHoverIcon from "../../assets/images/Organisation details_Black.svg";
import Logo from "../../assets/closeLogo.svg";
import classes from "./mainLayout.module.css";

import { RootState } from "../../store";
import ProfileIcon from "../../assets/images/Profile.svg";
import UserManagementIcon from "../../components/SVG/UserManagment";
import OrganisationProfileIcon from "../../assets/images/OrganisationProfile.svg";
import OrganisationIcon from "../../components/SVG/OrganisationProfile";
import userManagmentIcon from "../../assets/images/user_management.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateAppBarTitle } from "../../features/dashboardSlice";
import dashboardSlice from "../../features/dashboardSlice";
import { BorderStyle } from "@mui/icons-material";
// icon component
import MyProjectIcon from "../../components/SVG/MyProjectIcon";
import EAuctionIcon from "../../components/SVG/EAuctionIcon";
import CubeIcon from "../../components/SVG/CubeIcon";
import SupplierIcon from "../../components/SVG/SupplierIcon";
import ConversationIcon from "../../components/SVG/ConversationIcon";
import SpendClassificationIcon from "../../components/SVG/SpendClassificationIcon";
import SpIcon from "../../components/SVG/SupplierIcon";
import English from "../../assets/Us.png";
import German from "../../assets/German.png";
import Japanese from "../../assets/Japanese.png";
import Mandarin from "../../assets/Mandarin.png";
import UserManagement from "../../components/SVG/UserManagment";
import { updateLoader, LoaderType } from "../../features/loaderSlice";
import { clearShowArchivedList } from "../../features/spendCubeSlice";
import {
  NON_VERIFIED_USER_LOGIN_MESSAGE,
  SESSION_EXPIRED,
  SESSION_EXPIRED_MESSAGE,
  SOMETHING_WENT_WRONG,
  UPDATED_SUCCESSFULLY,
  USER_NOT_VERIFIED,
} from "../../constants/apiStatus";
import { sideNavItems } from "constants/dashboard";
import Notification from "components/Notification/Notification";
import { useTranslation } from "react-i18next";
import Organisation from "../../components/profiles/organisationProfile/OrgProfile/Organisation";
import { updateUsersProfile } from "services/apiHandler";
import { BASE_URL } from "services/endpoints";
import { updateLanguage } from "features/languageSlice";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: "#f0f0f0",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const dashboardStore = useSelector((state: RootState) => state.dashboard);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElLanguage, setAnchorElLanguage] =
    React.useState<null | HTMLElement>(null);
  const selectedLanguageData = localStorage.getItem("selectedLanguage");
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    selectedLanguageData ? selectedLanguageData : "en"
  );
  const languages = [
    {
      name: "English",
      code: "en",
      flag: English,
    },
    {
      name: "German",
      code: "de",
      flag: German,
    },
    {
      name: "Japanese",
      code: "ja",
      flag: Japanese,
    },
    {
      name: "Mandarin",
      code: "zh-Hans",
      flag: Mandarin,
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElLanguage(null);
  };

  const handleSelectLanguage = (item: any) => {
    if (item) {
      const newSelectedLanguage = item.code;
      console.log(newSelectedLanguage, "selectedLanguage");
      setSelectedLanguage(newSelectedLanguage);
      onSubmit(newSelectedLanguage);
      dispatch(updateLanguage(newSelectedLanguage));
      i18n.changeLanguage(newSelectedLanguage);
    }
    setAnchorElLanguage(null);
  };

  const onSubmit = async (newSelectedLanguage: any) => {
    dispatch(
      updateLoader({
        showSpinner: true,
        body: "",
        headerTitleText: "",
        modelType: "",
      })
    );

    const payload = {
      fullName: localStorage.getItem("firstName") || "", // Provide a default value of ''
      phone: localStorage.getItem("phone") || "",
      timeZone: localStorage.getItem("timeZone") || "",
      preferedLanguage: newSelectedLanguage,
    };

    const userId: any = localStorage.getItem("userId");

    await updateUsersProfile(payload, userId)
      .then((response: any) => {
        if (response.data.success) {
          localStorage.setItem("selectedLanguage", newSelectedLanguage);
          const pathId = location.pathname.split("/").pop();
          console.log("location.pathname", pathId);
          console.log(location);
          if (
            location.pathname ===
              `/source-to-contract/spendcube/details/${pathId}` ||
            location.pathname.startsWith("/create-rfi") ||
            location.pathname.startsWith("/supplier") ||
            location.pathname.startsWith("/view-rfi") ||
            location.pathname.startsWith("/view-rfq")
          ) {
            window.location.reload();
          }
          dispatch(
            updateLoader({
              showSpinner: true,
              body: t("language updated successfully"),
              headerTitleText: t(UPDATED_SUCCESSFULLY),
              modelType: LoaderType.success,
            })
          );
        } else {
          dispatch(
            updateLoader({
              showSpinner: true,
              body: "Failed to save",
              headerTitleText: "language update failed",
              modelType: LoaderType.error,
            })
          );
        }
      })
      .catch((error: any) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/login");
          localStorage.clear();
          updateLoader({
            showSpinner: true,
            body: SESSION_EXPIRED_MESSAGE,
            headerTitleText: SESSION_EXPIRED,
            modelType: LoaderType.error,
          });
        } else {
          updateLoader({
            showSpinner: true,
            body: error.response.data.message,
            headerTitleText: SOMETHING_WENT_WRONG,
            modelType: LoaderType.error,
          });
        }
      });
  };
  const tenantType = localStorage.getItem("tenantType");

  console.log(tenantType, "tenantType");

  const [activeNavIndex, setActiveNavIndex] = React.useState<number>(0);
  const isHomePath = location.pathname === "/dashboard";

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  React.useEffect(() => {
    dispatch(updateAppBarTitle("Executive Dashboard"));
    if (location.pathname === "/") {
      if (tenantType === "buyer") {
        // navigate('/source-to-contract/my-projects');
        navigate("/source-to-contract/executive-dashboard");
      } else {
        navigate("/supplier/my-projects");
      }
    }
    if (!(isAuthenticated === "true")) {
      navigate("/login");
    }
  }, []);

  //setLanguages

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuOptions = [
    {
      name: t("My Profile"),
      path: "/my-profile",
      icon: ProfileIcon,
    },
    {
      name: "Organization profile",
      path:
        tenantType === "buyer"
          ? "/organisation"
          : "/supplier-organisation-profile/organisation-details",
      icon: OrganisationProfileIcon,
    },
    {
      name: t("User Management"),
      path: "/user-management",
      icon: userManagmentIcon,
    },
  ];

  var sideNavMenuList: any = [];

  if (tenantType === "buyer") {
    sideNavMenuList = [
      {
        name: t("Executive Dashboard"),
        path: "/source-to-contract/executive-dashboard",
        icon: (
          <BarChartIcon
            className={classes.sideNavIcon}
            sx={{ color: "#fff", fontSize: "large" }}
          />
        ),
        hoverIcon: ProjectHoverIcon,
      },
      {
        name: "My Projects",
        path: "/source-to-contract/my-projects",
        icon: <MyProjectIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: ProjectHoverIcon,
      },
      {
        name: t("eAuction (events)"),
        path: "/source-to-contract/events",
        icon: <EAuctionIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: ProjectHoverIcon,
      },
      {
        name: t("Spend Cubes"),
        path: "/source-to-contract/spendcube",
        icon: <CubeIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: cubeHoverIcon,
      },
      {
        name: t("Spend Categorization"),
        path: "/source-to-contract/spend-categorization",
        icon: (
          <SpendClassificationIcon
            className={classes.sideNavIcon}
            color={"#fff"}
          />
        ),
        hoverIcon: cubeHoverIcon,
      },
      {
        name: t("Suppliers"),
        path: "/source-to-contract/supplier",
        icon: <SupplierIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: SupplierHoverIcon,
      },
      // {
      //   name: 'Conversations',
      //   path: '/source-to-contract/spendcube',
      //   icon: (
      //     <ConversationIcon
      //       className={classes.sideNavIconDisabled}
      //       color={'#fff'}
      //     />
      //   ),
      //   hoverIcon: conversationHoverIcon,
      // },
    ];
  } else {
    sideNavMenuList = [
      {
        name: "My Projects",
        path: "/supplier/my-projects",
        icon: <MyProjectIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: ProjectHoverIcon,
      },
      {
        name: t("eAuction (events)"),
        path: "/eaction/events",
        icon: <EAuctionIcon className={classes.sideNavIcon} color={"#fff"} />,
        hoverIcon: ProjectHoverIcon,
      },
      // {
      //   name: 'Conversation',
      //   path: '/supplier/conversation',
      //   icon: (
      //     <ConversationIcon
      //       className={classes.sideNavIconDisabled}
      //       color={'#fff'}
      //     />
      //   ),
      //   hoverIcon: conversationHoverIcon,
      // },
      {
        name: t("Organization Profile"),
        path: "/supplier-organisation-profile/organisation-details",
        icon: (
          <OrganisationIcon className={classes.sideNavIcon} color={"#fff"} />
        ),
        hoverIcon: OrganisationProfileHoverIcon,
      },
      {
        name: t("User Management"),
        path: "/user-management",
        icon: (
          <UserManagementIcon className={classes.sideNavIcon} color={"#fff"} />
        ),
        hoverIcon: UserManagementHoverIcon,
      },
    ];
  }

  React.useEffect(() => {
    setActiveNavIndex(
      sideNavMenuList.findIndex((item: any) => item.path === location.pathname)
    );
  }, [location.pathname]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const filteredSideNavMenuList = sideNavMenuList
    .map((item: any, originalIndex: any) => ({ ...item, originalIndex }))
    .filter(
      (item: any) =>
        !(
          BASE_URL == "https://core-api.supplyhub.ai" &&
          (item.name === t("Spend Categorization") ||
            item.name === t("eAuction (events)"))
        )
    );

  const drawerList = () => {
    return (
      <Drawer
        variant="permanent"
        open={open}
        sx={{ backgroundColor: "#999 !important" }}
        PaperProps={{
          sx: {
            backgroundColor: "#0B1B49",
            color: "#fff",
          },
        }}
      >
        <DrawerHeader>
          {open ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                className={classes.largeLogo}
                src={LargeLogo as string}
                onClick={() => {
                  tenantType === "buyer"
                    ? navigate("/source-to-contract/my-projects")
                    : navigate("/supplier/my-projects");
                }}
                alt="logo"
                style={{
                  width: "10rem",
                  margin: "auto",
                  position: "relative",
                  left: "-2rem",
                  cursor: "pointer",
                }}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginLeft: "-14px",
                  ...(open && { display: "block" }),
                }}
              >
                <MenuIcon
                  style={{ color: "#fff", marginRight: "10px !important" }}
                />
              </IconButton>
            </div>
          ) : (
            <div>
              {/* <img
              className={classes.logoIcon}
              src={Logo as string}
              alt='logo'
              onClick={() => {
                tenantType === 'buyer'
                  ? navigate('/source-to-contract/my-projects')
                  : navigate('/supplier/my-projects');
              }}
              style={{ cursor: 'pointer' }}
                /> */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                // sx={{
                //   marginLeft: '-14px',
                //   ...(open && { display: 'block' }),
                // }}
              >
                <MenuIcon
                  style={{ color: "#fff", marginRight: "10px !important" }}
                />
              </IconButton>
            </div>
          )}
        </DrawerHeader>
        <Divider />
        {/* <List>
          {filteredSideNavMenuList.map((item: any, index: any) => (
            
            <Tooltip title={!open ? item.name : ""} placement="right">
              <ListItem
                key={index}
                disablePadding
                className={classes.listItem}
                sx={{
                  display: "block",
                  backgroundColor:
                    index === activeNavIndex ? "#504FE9" : "transparent",
                  color: "#fff",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: index < 4 ? "#504FE9" : "transparent",
                    color: "#fff",
                    border: "none",
                  },
                }}
                onClick={() => {
                  // if (
                  //   (tenantType === 'buyer' && index > 3) ||
                  //   (tenantType === 'supplier' && index == 1)
                  // ) {
                  //   return;
                  // }
                  if (index == 1) {
                    dispatch(clearShowArchivedList(null));
                  }
                  navigate(item.path);
                  dispatch(updateAppBarTitle(item.name));
                  setActiveNavIndex(index);
                }}
              // onMouseEnter={() => handleMouseEnter(index)}
              // onMouseLeave={() => handleMouseLeave(index)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  disableRipple={
                    tenantType === "buyer" ? (index > 5 ? true : false) : false
                  }
                  disabled={
                    tenantType === "buyer" ? (index > 5 ? true : false) : false
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>

                  <ListItemText
                    className={index > 1 ? classes.navItemList : ""}
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List> */}
        <List>
          {filteredSideNavMenuList.map((item: any, index: any) => (
            <Tooltip
              key={index}
              title={!open ? item.name : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                className={classes.listItem}
                sx={{
                  display: "block",
                  backgroundColor:
                    item.originalIndex === activeNavIndex
                      ? "#504FE9"
                      : "transparent",
                  color: "#fff",
                  width: "100%",
                  "&:hover": {
                    backgroundColor:
                      item.originalIndex < 4 ? "#504FE9" : "transparent",
                    color: "#fff",
                    border: "none",
                  },
                }}
                onClick={() => {
                  if (item.originalIndex === 1) {
                    dispatch(clearShowArchivedList(null));
                  }
                  navigate(item.path);
                  dispatch(updateAppBarTitle(item.name));
                  setActiveNavIndex(item.originalIndex);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  disableRipple={
                    tenantType === "buyer"
                      ? item.originalIndex > 5
                        ? true
                        : false
                      : false
                  }
                  disabled={
                    tenantType === "buyer"
                      ? item.originalIndex > 5
                        ? true
                        : false
                      : false
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={
                      item.originalIndex > 1 ? classes.navItemList : ""
                    }
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <List
          sx={{
            position: "absolute",
            bottom: "1rem",
            width: "100%",
          }}
        >
          <Tooltip title={!open ? "Log Out" : ""} placement="right">
            <ListItem
              className={classes.logoutContainer}
              sx={{
                padding: 0,
                "&:hover": {
                  background: "#504FE9",
                },
              }}
            >
              <ListItemButton
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "#fff",
                  }}
                >
                  <LogoutOutlinedIcon className={classes.logoutIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={"Log Out"}
                  sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
    );
  };

  const headerWidth = () => {
    if (open && !isHomePath) {
      return "calc(100% - 240px)";
    } else if (!open && !isHomePath) {
      return "calc(100% - 57px)";
    } else {
      return "100%";
    }
  };

  const companyName = localStorage.getItem("tenantCompanyName");
  return (
    <Box sx={{ display: "flex", overflowX:'hidden' }} >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: location.pathname === "/dashboard" ? "#504FE9" : "#fff",
        }}
        style={{
          width: headerWidth(),
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.2), -2px -2px 1px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        }}
        // elevation={isHomePath ? 1 : 'none'}
      >
        <Toolbar style={{ overflow: "hidden" }}>
          {!isHomePath ? (
            <React.Fragment>
              {/* <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{
                  marginLeft: '-14px',
                  ...(open && { display: 'block' }),
                }}
              >
                <MenuIcon
                  style={{ color: '#455a64', marginRight: '10px !important' }}
                />
              </IconButton> */}

              <SpIcon />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  color: "#455a64",
                  position: "relative",
                  left: "-16px",
                  bottom: "2px",
                }}
              >
                <span
                  style={{
                    color:
                      dashboardStore?.subCatagories?.length > 0
                        ? "#0460A9"
                        : "",
                    cursor: dashboardStore?.path ? "pointer" : "",
                  }}
                  onClick={() => {
                    console.log(dashboardStore);
                    if (dashboardStore?.path) console.log(dashboardStore.path);
                    navigate(dashboardStore.path);
                  }}
                >
                  {dashboardStore?.appBarTitle}
                </span>
                {dashboardStore?.subCatagories?.map((item) => (
                  <span> | {item}</span>
                ))}
              </Typography>
            </React.Fragment>
          ) : (
            <img
              className={classes.logoIcon}
              src={dashboardLogo as string}
              alt="dashboard logo"
              style={{
                width: "11rem",
                margin: "auto",
                position: "absolute",
                left: "1rem",
              }}
            />
          )}

          {/* Profile section */}
          <Tooltip title={companyName}>
            <div
              className={`${classes.organisationName} app-theme-cta-background`}
            >
              <p> {companyName} </p>
            </div>
          </Tooltip>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              position: "absolute",
              right: -4,
              color: "#455A64",
            }}
          >
            <IconButton
              className={classes.languageDropdownContainer}
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
              id="basic-button"
              aria-controls={
                Boolean(anchorElLanguage) ? "basic-menu" : undefined
              }
              // aria-haspopup="true"
              aria-expanded={Boolean(anchorElLanguage) ? "true" : undefined}
              onClick={handleClick}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              {languages.find((item) => item.code === selectedLanguage)
                ?.flag && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {(() => {
                    const selectedLanguageItem = languages.find(
                      (item) => item.code === selectedLanguage
                    );
                    if (!selectedLanguageItem) return null;

                    return (
                      <>
                        <span>
                          <img
                            style={{
                              height: "20px",
                              width: "30px",
                              borderRadius: "3px",
                              border: "1px solid #f0f0f0",
                            }}
                            src={selectedLanguageItem.flag}
                            alt={`${selectedLanguageItem.name} flag`}
                          />
                        </span>
                        <span style={{ fontSize: "1.1rem" }}>
                          {selectedLanguageItem.name}
                        </span>
                      </>
                    );
                  })()}
                </div>
              )}
              <KeyboardArrowDownIcon />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorElLanguage}
              open={Boolean(anchorElLanguage)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <span
                  style={{
                    padding: "1px",
                    fontWeight: 800,
                    fontSize: "0.8rem",
                  }}
                >
                  Languages
                </span>
                {languages.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleSelectLanguage(item);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 15,
                      width: "100%",
                      minWidth: "140px",
                      // borderBottom:
                      //   index !== languages.length - 1
                      //     ? "0.5px solid grey "
                      //     : "",
                      padding: 0,
                    }}
                  >
                    <span style={{ fontSize: "2rem" }}>
                      {" "}
                      <img
                        style={{
                          height: "20px",
                          width: "25px",
                          borderRadius: "3px",
                          border: "1px solid #f0f0f0",
                        }}
                        src={item.flag}
                        alt={"languages img"}
                      />
                    </span>
                    <span style={{ fontSize: "1.1rem" }}>{item.name} </span>
                    {/* {selectedLanguage === item.code && (
                      <span style={{ fontSize: "0.9rem" }}>(Default)</span>
                    )} */}
                  </MenuItem>
                ))}
              </div>
            </Menu>

            <Notification />
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <CustomAvatar
                sx={{
                  minHeight: 20,
                  minWidth: 20,
                  fontSize: 14,
                  margin: "auto",
                  backgroundColor: "lavender",
                  color: "blue",
                }}
              >
                {localStorage.getItem("firstName")}
              </CustomAvatar>
              <KeyboardArrowDownIcon
                sx={{
                  color:
                    location.pathname === "/dashboard" ? "#fff" : "#455A64",
                }}
              />
            </IconButton>
            <Menu
              sx={{ mt: "45px", right: "1rem", width: "20rem" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 8,
                  p: 2,
                  borderBottom: "1px  solid  #e0e0e0 ",
                  borderBottomStyle: "dashed",
                }}
              >
                <CustomAvatar
                  sx={{
                    minHeight: 20,
                    minWidth: 20,
                    fontSize: 14,
                    margin: "auto",
                    backgroundColor: "lavender",
                    color: "blue",
                  }}
                >
                  {localStorage.getItem("firstName")}
                </CustomAvatar>
                <Box
                  sx={{
                    position: "relative",
                    left: "-50px",
                  }}
                >
                  <Typography> {localStorage.getItem("firstName")} </Typography>
                  <Typography
                    sx={{
                      color: "#999",
                      fontSize: ".8rem",
                      width: "100%",
                      whiteSpace: "normal",
                    }}
                  >
                    {" "}
                    {localStorage.getItem("email")}
                  </Typography>
                </Box>
              </Box>

              {menuOptions.map((item, i) =>
                item.path === "/user-management" &&
                !(localStorage.getItem("userVerified") === "true") ? null : (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate(item.path);
                    }}
                  >
                    <img src={item.icon} alt={item.name} />
                    <Typography
                      sx={{
                        width: "100%",
                        marginLeft: ".5rem",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                )
              )}

              <MenuItem
                sx={{
                  borderTop: "1px solid #e0e0e0",
                  borderStyle: "dashed",
                }}
                key={"logout"}
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                <Typography textAlign="center">{t("Log Out")}</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {!isHomePath && drawerList()}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box
          sx={{
            backgroundColor: "#f9f9fa",
            p: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
