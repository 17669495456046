import React from 'react';
import classes from './Spinner.module.css';
import { useSelector, useDispatch } from 'react-redux';
import DialogModel from 'components/models/DialogModel';
import loaderImg from '../../../assets/loader.svg';
import { updateLoader } from '../../../features/loaderSlice';
import { LoaderType } from '../../../features/loaderSlice';

const Spinner = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const loader = useSelector((state: any) => state.loader);

  if (!loader.showSpinner) {
    return null;
  } else if (
    loader.showSpinner &&
    loader.body === '' &&
    loader.headerTitleText === ''
  ) {
    return (
      <div className={classes.spinnerContainer}>
        <img src={loaderImg} alt='loader' className={classes.loader} />
      </div>
    );
  } else if (
    loader.showSpinner &&
    loader.body !== '' &&
    loader.headerTitleText !== ''
  ) {
    return (
      <DialogModel
        headerTitleText={loader.headerTitleText}
        body={loader.body}
        modelType={loader.modelType}
        isOpen={true}
        handleCloseForce={(): void => {
          dispatch(
            updateLoader({ showSpinner: false, body: '', headerTitleText: '' })
          );
        }}
      />
    );
  }

  return null;
};

export default Spinner;
