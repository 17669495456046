// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePassword_loginContainer__Qp9pC {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 50px;\n    box-sizing: border-box;\n    height: 100vh;\n    padding-top: 10%;\n  }\n  \n  .CreatePassword_logo__xZ-q- {\n    height: 45px;\n    width: 240px;\n  }\n  \n  .CreatePassword_welcomeNote__uzt20 {\n    font-size: 25px;\n    margin-top: 10px;\n  }\n  \n  .CreatePassword_signInText__lIQrA {\n    margin-bottom: 20px;\n  }\n  \n  .CreatePassword_errorColour__S-SQf {\n    color: #d32f2f !important;\n  }\n  \n  /* Responsive Styles */\n  @media screen and (max-width: 768px) {\n    .CreatePassword_loginContainer__Qp9pC {\n      padding: 30px;\n    }\n  \n    .CreatePassword_signInText__lIQrA {\n      margin-bottom: 15px;\n    }\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/registration/createPassword/CreatePassword.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,sBAAsB;EACtB;IACE;MACE,aAAa;IACf;;IAEA;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".loginContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 50px;\n    box-sizing: border-box;\n    height: 100vh;\n    padding-top: 10%;\n  }\n  \n  .logo {\n    height: 45px;\n    width: 240px;\n  }\n  \n  .welcomeNote {\n    font-size: 25px;\n    margin-top: 10px;\n  }\n  \n  .signInText {\n    margin-bottom: 20px;\n  }\n  \n  .errorColour {\n    color: #d32f2f !important;\n  }\n  \n  /* Responsive Styles */\n  @media screen and (max-width: 768px) {\n    .loginContainer {\n      padding: 30px;\n    }\n  \n    .signInText {\n      margin-bottom: 15px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "CreatePassword_loginContainer__Qp9pC",
	"logo": "CreatePassword_logo__xZ-q-",
	"welcomeNote": "CreatePassword_welcomeNote__uzt20",
	"signInText": "CreatePassword_signInText__lIQrA",
	"errorColour": "CreatePassword_errorColour__S-SQf"
};
export default ___CSS_LOADER_EXPORT___;
