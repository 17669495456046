import { useState, useEffect } from 'react';
import { getAllNotification } from 'services/apiHandler';

const useNotification = (pageNumber: number) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  useEffect(() => {
    fetchNotifications();
  }, [pageNumber]);

  const fetchNotifications = (appendToPrevious: boolean = true) => {
    setLoading(true);
    setError(false);
    getAllNotification(appendToPrevious ? pageNumber : 0)
      .then((response: any) => {
        setLoading(false);
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          if (appendToPrevious) {
            setNotifications((prevState: any) => [
              ...prevState,
              ...response.data.data,
            ]);
          } else {
            setNotifications([...response.data.data]);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
      });
  };



  return { loading, error, notifications, setNotifications, fetchNotifications };
};

export default useNotification;
