import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INewMessagePayload {
  channelId: string;
  projectId: string;
  userId: string | null; // Assuming it can be null if not available in local storage
  message: string;
  firstName: string;
  timestamp: Date;
}

const initialState = {
  appBarTitle: '',
  path: '',
  subCatagories: [],
  notificationCounts: null,
  showNotificationPanel: false,
  openConversationModel: false,
  channelList: [],
  selectedChannelData: {},
  conversations: [],
  newConversationCounts: [],
  conversationOffset: 0,
  newMessageFound: 0
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateAppBarTitle(state, action) {
      const { appBarTitle, subCatagories, path } = action.payload;
      state.appBarTitle = appBarTitle;
      state.subCatagories = subCatagories;
      state.path = path;
    },

    setNotificationCounts(state, action) {
      state.notificationCounts = action.payload;
    },
    setShowNotificationPanel(state, action) {
      state.showNotificationPanel = action.payload;
    },
    toggleConversationModel(state, action) {
      state.openConversationModel = action.payload;
      state.selectedChannelData = {};
      state.conversations = [];
      state.channelList = [];
    },
    setChannelList(state, action) {
      const {channelList, updateConversation} = action.payload;
      state.channelList = channelList;
      if (updateConversation && channelList.length > 0) {
        state.selectedChannelData = channelList[0];
      }
    },
    setSelectedChannelData(state, action) {
      state.conversationOffset = 0;
      state.selectedChannelData = action.payload;
    },

    setConversations(state, action) {
      state.conversations = action.payload;
    },
    setNewConversationCounts(state, action) {
      if(action.payload && Array.isArray(action.payload)){
        state.newConversationCounts= action.payload as any;

        if( action.payload &&  action.payload.length > 0){
          let currentChannel:any = state?.selectedChannelData;
          let isNewMessagefound = action.payload.filter((item: any)=> {
            if(item.channelId === currentChannel?.channelId ){
              return item;
            }
          })[0];
          if(isNewMessagefound){
            state.conversations=[]
            state.newMessageFound =+1
            state.conversationOffset = 0;
            
          }
        }
      }
      
    },
    saveNewMessage(state: any, action: PayloadAction<INewMessagePayload>) {
      state.conversations.push(action.payload);
    },
    setConversationOffset(state, action) {
      state.conversationOffset = action.payload;
    },
  },
});

export const {
  updateAppBarTitle,
  setNotificationCounts,
  setShowNotificationPanel,
  toggleConversationModel,
  setChannelList,
  setSelectedChannelData,
  setConversations,
  setNewConversationCounts,
  setConversationOffset,
  saveNewMessage,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
