import i18n from "i18next";
import ICU from "i18next-icu";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en, pt, sp, fh, ja, zhHans, de } from "./languages";

i18n
  .use(ICU)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: en,
      },
      pt: {
        translations: pt,
      },
      sp: {
        translations: sp,
      },
      fh: {
        translations: fh,
      },
      ja: { translations: ja },
      "zh-Hans": { translations: zhHans },
      de: { translations: de },
    },
    fallbackLng: localStorage.getItem("selectedLanguage") || "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
