import { createSlice } from '@reduxjs/toolkit';

export enum LoaderType {
  error = 'error',
  success = 'success',
  confirm = 'confirm',
  action = 'action',
  warning = 'warning',
  test = 'test',
  confirmRFI = 'confirmRFI',
  rfqNextRoundConfirm = 'rfqNextRoundConfirm',
  approveInbox='approveInbox',
  rejectInbox = 'rejectInbox',
  confirmAward = 'confirmAward',
}

interface ILoaderState {
  showSpinner: boolean;
  modelType: LoaderType | '';
  body: string | null;
  headerTitleText: string | null;
}

const initialState: ILoaderState = {
  showSpinner: false,
  body: null,
  headerTitleText: null,
  modelType: '',
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    updateLoader(state, action) {
      const { showSpinner, body, headerTitleText, modelType } = action.payload;
      state.showSpinner = showSpinner;
      state.body = body;
      state.headerTitleText = headerTitleText;
      state.modelType = modelType || '';
    },
  },
});

export const { updateLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
