import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import ButtonContained from '../../ButtonContained/ButtonContained';
import classes from './CreatePassword.module.css';
import { createPassword } from '../../../services/apiHandler';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import LargeLogo from '../../../assets/LOGO_NEW.svg';
import { setUserProfile } from '../../.../../../features/userSlice';
import { useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import PasswordPolicy from '../passwordPolicy/PasswordPolicy';
import TermsConditions from 'components/models/termsConditionsDialog/termsConditions';
import { updateLoader, LoaderType } from '../../../features/loaderSlice';
import {
  LOGGED_IN_SUCCESSFULLY,
  LOGGED_IN_SUCCESS_MESSAGE,
  // LOGGED_IN_FAILED,
  // LOGGED_IN_FAILED_MESSAGE,
} from '../../../constants/apiStatus'
import { useTranslation } from 'react-i18next';
type FormValues = {
  email: string;
  password: string;
  terms: boolean;
};

const CreatePassword = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });

  var passwordPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const password = useWatch({ control, name: 'password' });
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);
  const [displayPasswordPolicy, setDisplayPasswordPolicy] = useState(false)
  const location = useLocation()
  const [termsAccepted, setTermsAccepted] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeModal = () => {
    setOpen(false)
  };

  const handleAcceptTerms = () => {
    setValue('terms', true, { shouldValidate: true });
    setTermsAccepted(true);
    // Set the value of the 'terms' field to true
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (errors.password) {
      setDisplayPasswordPolicy(true)
    }
  }, [errors.password])


  const onSubmit = async (data: FormValues) => {
    const payload = {
      password: data.password
    }

    const response: any = await createPassword(payload, searchParams.get('id'));
    console.log(response)
    if (response.data.status) {
      setError(false);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem('userVerified', response.data.data.userVerified);
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('userId', response.data.data.userId);
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('firstName', response.data.existingUser.firstName);
        localStorage.setItem('lastName', response.data.existingUser.lastName);
        localStorage.setItem('tenantId', response.data.existingUser.tenantId);
        localStorage.setItem('timeZone', response.data.existingUser.preferedTimeZone);
        localStorage.setItem('refreshToken', response.data.data.refreshToken);
        localStorage.setItem('rememberMe', response.data.existingUser.rememberMe);
        localStorage.setItem('permissions', JSON.stringify(response.data.existingUser.permissions));
        localStorage.setItem('phone', response.data.existingUser.phone);
        localStorage.setItem('tenantType', response.data.tenantType);
        
      const userObj = {
        firstName: response.data.existingUser.firstName,
        lastName: response.data.existingUser.lastName,
        email: response.data.existingUser.email,
      };
      dispatch(setUserProfile(userObj));
      if(response.data.tenantType === 'buyer'){
        navigate('/source-to-contract/my-projects');
      } else {
        navigate('/supplier/my-projects');
      }
      if (
        localStorage.getItem('loginUserVerified') === 'true' &&
        localStorage.getItem('userVerified') === 'true'
      ) {
        dispatch(
          updateLoader({
            showSpinner: true,
            body: LOGGED_IN_SUCCESS_MESSAGE,
            headerTitleText: LOGGED_IN_SUCCESSFULLY,
            modelType: LoaderType.success,
          })
        );
      }
    } else {
      setError(true);
      setMessage(response.data.message);
    }
  };
  return (
    <>
      <TermsConditions open={open} closeModal={closeModal} handleAcceptTerms={handleAcceptTerms} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.loginContainer}>
          <img src={LargeLogo} className={classes.logo} />

          <p className={classes.signInText}>{t("Sign in to your account")}</p>
          <TextField
            id='outlined-basic'
            label={t('Buisness Email ID')}
            className={error ? classes.errorColour : ''}
            placeholder={t('Buisness Email ID')||''}
            variant='outlined'
            value={searchParams.get('email')}
            disabled={true}
            sx={{ marginTop: '1.5rem' }}
          />
          <br />

          <FormControl
            variant='outlined'
            error={errors.password ? true : false}
            style={{ position: 'relative' }}
          >
            {displayPasswordPolicy ?
              <PasswordPolicy
                style={{ position: "absolute" }}
                isMinlength={password.length >= 8 ? true : false}
                hasSpecialCharacter={passwordPattern.test(password) ? true : false}
              />
              : null}
            <InputLabel htmlFor='outlined-adornment-password' className={error ? classes.errorColour : ''}>
              {t("Create Password")}
            </InputLabel>

            <OutlinedInput
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              className={error ? classes.errorColour : ''}
              onFocus={() => setFocused(true)}
              {...register('password', {
                
                required: {
                  value:true,
                 message: t('Password is required'),
                },
                minLength: {
                  value: 8,
                  message: t('Password must be at least 8 characters'),
                },
                pattern: {
                  value: /^(?=.*[!@#$%^&*])/,
                  message:t( 'Password must contain at least one special character'),
                },
                onBlur: () => {
                  setFocused(false);
                  setDisplayPasswordPolicy(false)
                }
              },)}
              error={Boolean(errors.email) || error}
              endAdornment={
                focused ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{ color: '#504FE9' }}
                          className={error ? classes.errorColour : ''}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ color: '#504FE9' }}
                          className={error ? classes.errorColour : ''}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              label={t('Create Password')}
            />
            <FormHelperText>
              {errors.password && errors.password.message}
            </FormHelperText>
          </FormControl>
          {error ? <p className={classes.errorColour}>{message}</p> : null}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem", marginTop: "1rem" }}>

            <div className={classes.terms}>
              <Checkbox
                {...register('terms', {

                  required:{
                    value:true,
                    message:t('Please accept the Terms & Conditions'),
                  },
                   
                  onChange: (e) => setTermsAccepted(e.target.checked)
                })}
                className='app-theme-color'
                checked={termsAccepted}
              />{' '}
              <span style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                {t("I accept the")}{' '}
                <a className='app-theme-color'>{t('Terms & Conditions')}</a>
              </span>{' '}

            </div>

          </div>

          <ButtonContained
            type='submit'
            className={!isValid ? 'buttonDisabled' : 'buttonEnabled'}
            disabled={!isValid}
          >
            {t("Register Now")}{' '}
          </ButtonContained>
        </div>
      </form>
    </>
  );
};

export default CreatePassword;
