import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    email: localStorage.getItem('email') || '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(state, action) {
      state.userInfo.firstName = action.payload.firstName;
      state.userInfo.lastName = action.payload.lastName;
      state.userInfo.email = action.payload.email;
    },
  },
});

export const { setUserProfile } = userSlice.actions;
export default userSlice.reducer;
