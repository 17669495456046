import React, { SVGProps } from 'react';

const SupplierIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || 20}
      height={props.height || 20}
      fill={props.color || '#fff'}
      className={props.className || ''}
      {...props}
    >
      <path
        fill={props.color || '#fff'}
        d='M8 1.333A3.17 3.17 0 0 0 4.833 4.5c0 1.713 1.34 3.1 3.087 3.16a.538.538 0 0 1 .147 0h.046a3.159 3.159 0 0 0 3.054-3.16A3.17 3.17 0 0 0 8 1.333ZM11.387 9.44c-1.86-1.24-4.894-1.24-6.767 0-.847.56-1.313 1.327-1.313 2.147 0 .82.466 1.58 1.306 2.14.934.626 2.16.94 3.387.94 1.227 0 2.453-.314 3.387-.94.84-.567 1.306-1.327 1.306-2.154-.006-.813-.466-1.58-1.306-2.133Zm-1.834 1.6-1.68 1.68a.414.414 0 0 1-.293.12.444.444 0 0 1-.293-.12l-.84-.84a.419.419 0 0 1 0-.587c.16-.16.426-.16.586 0l.547.547 1.387-1.387c.16-.16.426-.16.586 0a.407.407 0 0 1 0 .587Z'
      />
    </svg>
  );
};

export default SupplierIcon;
