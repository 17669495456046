import classes from './policy.module.css';

const TermsPolicy = () => {

    return (

        <div
            style={{ minWidth: '100%' }}
        >

            <div >
                <div >
                    <h1 className={classes.contentTitle}>
                        1. Acceptance of Terms of Use
                    </h1>
                    <p>This website and the materials made available by and through this
                        website are provided by Spendworkz Inc. (“Spendworkz”) for use by its
                        customers, consumers, employees, and ecosystem partners and is
                        intended for personal and informational use only. By accessing,
                        browsing, or using this website or the materials, you accept and agree
                        to be bound by these Terms of Use and any additional terms and
                        conditions which may accompany the materials made available through
                        this website (collectively, the “Terms”). If you are an individual
                        acting on behalf of an entity, you represent and warrant that you have
                        the authority to agree to the Terms on behalf of that entity and to
                        bind that entity to the Terms. IF YOU DO NOT AGREE TO THE TERMS, DO
                        NOT ACCESS OR USE THIS WEBSITE OR ANY MATERIALS FROM IT.</p>

                    <h1 className={classes.contentTitle}>
                        2. Scope of Terms. Modifications. Translations.{' '}
                    </h1>


                    <p>
                        <b>
                            <span className={classes.contentTitle}>
                                2.1 Application:
                            </span>
                        </b>
                        The Terms apply to all websites where these Terms are posted,
                        displayed, or referenced ("Websites"), whether the sites are
                        sponsored, managed, or hosted by Spendworkz or third parties. The Terms
                        also apply to any resources and tools provided through the Websites
                        and to documents made available on the Websites such as white papers,
                        press releases, datasheets, and specification documents. All materials
                        provided by the Websites will be referred to as the "Materials."
                    </p>
                    <h1 className={classes.contentTitle}>
                        2.2 Translations of Terms:
                    </h1>
                    <p >
                        Where Spendworkz has provided you with a translation of the English
                        language version of these Terms, you agree that the translation is
                        provided for your convenience only and that the English-language
                        versions of these Terms will govern your relationship with Spendworkz.
                        If there is any contradiction between the English-language version of
                        these Terms and the translation, the English-language version will
                        govern and control.
                    </p>
                    <h1 className={classes.contentTitle}>
                        3. Security and Privacy
                    </h1>
                    <p>
                        <b>
                            <span className={classes.contentTitle}>
                                {' '}
                                3.1 Personal Information:
                            </span>
                        </b>
                        Personal information you submit to Spendworkz through forms on the
                        Websites or the Materials is governed by Spendworkz’s
                        &nbsp;<a href="#privacy-policy" className={classes.link}>privacy policy,</a> which describes how Spendworkz collects, processes, and
                        discloses your personal information. By accessing and using the
                        Websites, you acknowledge that you have read and understand the
                        Privacy Notice.

                    </p>
                    <h1 className={classes.contentTitle}>
                        3.2 Misuse of Account:
                    </h1>
                    <p>
                        Spendworkz will not be liable to you or to any third party for any loss
                        that you may incur as a result of someone else using your password or
                        account, either with or without your knowledge. However, Spendworkz
                        reserves the right to hold you liable for losses incurred by Spendworkz
                        or by a third party due to someone else using your account or
                        password.
                    </p>
                    <h1 className={classes.contentTitle}>
                        3.3 Disabling or Suspension of Account:
                    </h1>
                    <p > Spendworkz has the right to disable or suspend any account at any time
                        in Spendworkz's sole and absolute discretion, no matter the reason,
                        without notice to you.
                    </p>
                    <h1 className={classes.contentTitle}>
                        4. Use of Websites and Materials{' '}
                    </h1>
                    <p>
                        <b>
                            <span className={classes.contentTitle}>
                                4.1 No Unlawful or Prohibited Use:&nbsp;
                            </span>
                        </b>


                        You agree that you will not use the Websites or the Materials for any
                        purpose that is unlawful or prohibited by the Terms. You must not:
                        <br />
                        4.1.1 Upload, post, email, transmit, or otherwise make available any
                        content that is unlawful, harmful, threatening, abusive, harassing,
                        tortious, defamatory, vulgar, obscene, libelous, invasive of another's
                        privacy, hateful, or racially, ethnically, or otherwise objectionable;
                        <br />
                        4.1.2 Use the Websites or the Materials to stalk or otherwise harass
                        or harm another;
                        <br />
                        4.1.3 Impersonate any person or entity, including but not limited to
                        an Spendworkz official, forum leader, guide, or host, or falsely state
                        or otherwise misrepresent your affiliation with Spendworkz or any
                        person or entity, or collect or store personal data about other users
                        in connection with any prohibited conduct or activities;
                        <br />
                        4.1.4 Forge headers or otherwise manipulate identifiers in order to
                        disguise the origin of any content transmitted through the Websites or
                        the Materials;
                        <br />
                        4.1.5 Upload, post, email, transmit, or otherwise make available any
                        content that you do not have the right to make available under any law
                        or under contractual or fiduciary relationships (such as inside
                        information or other proprietary and confidential information learned
                        or disclosed as part of employment relationships or under
                        nondisclosure agreements);
                        <br />
                        4.1.6 Upload, post, email, transmit, or otherwise make available any
                        content that infringes or misappropriates any patent, trademark, trade
                        secret, copyright, or any other proprietary rights of Spendworkz or any
                        third party;
                        <br />
                        4.1.7 Upload, post, email, transmit, or otherwise make available any
                        unsolicited or unauthorized advertising, promotional materials, junk
                        mail, spam, chain letters, pyramid schemes, or any other form of
                        solicitation;
                        <br />
                        4.1.8 Upload, post, email, transmit, or otherwise make available any
                        material that contains software viruses or any other computer code,
                        files, or programs designed to interrupt, destroy, or limit the
                        functionality of any computer software or hardware or
                        telecommunications equipment;
                        <br />
                        4.1.9 Use the Websites or the Materials in any manner that could
                        damage, disable, overburden, or impair any Spendworkz server or
                        network(s) connections, disobey any requirements, procedures,
                        policies, or regulations of networks connected to the Websites or the
                        Materials, or interfere with any other party's use and enjoyment of
                        the Websites or the Materials;
                        <br />
                        4.1.10 Circumvent any use-limitation or restricted-access technology
                        used by the Websites or the Materials, or otherwise attempt to gain
                        unauthorized access to the Websites or the Materials, or any other
                        accounts, computer systems, or networks connected to any Spendworkz
                        server or the Materials, through hacking, password mining, or any
                        other means, or obtain or attempt to obtain any materials or
                        information through any means not intentionally made available through
                        the Websites or the Materials;
                        <br />
                        4.1.11 Intentionally or unintentionally violate any applicable local,
                        state, national, or international law, including but not limited to
                        regulations promulgated by the U.S. Securities and Exchange
                        Commission, any rules of any national or other securities exchange,
                        including without limitation the New York Stock Exchange, the American
                        Stock Exchange, or the NASDAQ, and any regulations having the force of
                        law;
                        <br />
                        4.1.12 Provide material support or resources (or conceal or disguise
                        the nature, location, source, or ownership of material support or
                        resources) to any organization designated by the United States
                        government as a foreign terrorist organization;
                        <br />
                        4.1.13 Remove any copyright or other proprietary rights notices placed
                        upon the Websites or Materials;
                        <br />
                        4.1.14 Perform automated searches against Spendworkz's systems (except
                        for non-burdensome federated search services), including using
                        automated "bots," link checkers, or other scripts or web scraping
                        technologies, without the prior written permission of Intel;
                        <br />
                        4.1.15 Use the Websites or the Materials to create products or perform
                        services which compete or interfere with those of Spendworkz;
                        <br />
                        4.1.16 Text mine, data mine, or harvest metadata from the Websites or
                        the Materials; or
                        <br />
                        4.1.17 Download all or parts of the Websites or the Materials in a
                        systematic or regular manner or so as to create a collection of
                        materials comprising all or a material subset of the Websites or the
                        Materials, in any form.
                    </p>
                    <h1 className={classes.contentTitle}>
                        4.2 Enforcement:
                    </h1>
                    <p>
                        Enforcement of this section is solely at Spendworkz's discretion, and
                        failure to enforce this section in some instances does not constitute
                        a waiver of its right to enforce it in other instances. In addition,
                        this section does not create any private right of action on the part
                        of any third party or any reasonable expectation that the Websites or
                        Materials will not contain any content or conduct that is prohibited
                        by this section.
                    </p>
                    <h1 className={classes.contentTitle}>

                        5. Retained Rights. Patents.
                    </h1>
                    <p>
                        <b>
                            <span className={classes.contentTitle}>
                                5.1 Retained Rights:&nbsp;
                            </span>
                        </b>
                        You acknowledge and agree that Spendworkz or its licensors own all
                        legal rights, title, and interest in and to the Websites and the
                        Materials, and that the Websites and Materials are protected by
                        worldwide copyright laws and treaty provisions. Except as set forth in
                        these Terms and unless otherwise specified in the Materials themselves
                        or pursuant to any license terms or permissions for the Materials, (a)
                        no license rights or other rights are granted to you under any
                        patents, copyrights, trademarks, or trade secret information, whether
                        by implication, estoppel or otherwise, and (b) you may not modify,
                        copy, distribute, transmit, display, perform, publish, license, create
                        derivative works from, transfer, or sell the Websites or the
                        Materials. Any unauthorized use of the Websites or the Materials may
                        infringe Spendworkz's or a third party’s copyright rights, trademark
                        rights, or other proprietary rights, may violate laws relating to
                        privacy and publicity, and may result in civil or criminal liability.
                    </p>
                    <h1 className={classes.contentTitle}>
                        6. Notices Related to Content
                    </h1>
                    <p>
                        <b>
                            <span className={classes.contentTitle}>
                                6.1 Trademarks:
                            </span>
                        </b>

                        Any trade names, trademarks, service marks, logos, trade dress, and
                        other brand features (“Trademarks”) displayed on or featured in the
                        Websites or the Materials are the property of either Spendworkz or
                        third-party licensors. Nothing in these Terms grants you any license
                        rights or other rights to use or display any such Trademarks.
                    </p>
                    <p>
                        <span className={classes.contentTitle} >
                            <b> 6.2 Third Party Content:</b>
                        </span>
                        The Websites and the Materials may contain third party submitted
                        content. Such content is not reviewed, approved, or endorsed by
                        Spendworkz and is provided solely as a convenience to you. Under no
                        circumstances will Spendworkz be liable to you or to any third party in
                        any way for any third-party submitted or provided content, including
                        but not limited to any errors or omissions, or damages of any kind. By
                        your use of the Websites, you agree that you must evaluate, and bear
                        all risks associated with, the use of any third party content,
                        including any reliance on the accuracy, completeness, or usefulness of
                        such third party content. 6.4 Copyright Permission: Except for
                        confidential Spendworkz information disclosed via the Websites, use of
                        content on the Websites or the Materials is authorized for editorial
                        (i.e., news media) purposes only and is subject to the Terms.
                    </p>
                    <h1 className={classes.contentTitle}>
                        7. Notices Specific to Spendworkz Documents
                    </h1>
                    <p >
                        <span className={classes.contentTitle}> <b> 7.1 Permission to Use: </b> </span>
                        Permission to use any Spendworkz-owned white papers, press releases,
                        datasheets, specification documents, FAQs, etc. ("Documents") made
                        available as Materials via the Websites is granted, provided that (a)
                        the copyright notice at the bottom of these Terms and this permission
                        notice appear in all copies, (b) you may use Documents only for your
                        informational and non-commercial personal use, (c) the Documents will
                        not be copied or posted on any network computer or broadcast in any
                        media, (d) no modifications of any Documents are made, and (e) you are
                        otherwise in compliance with all of the Terms. Any other uses require
                        the express written permission of Intel, which may be withheld in
                        Spendworkz's sole discretion.
                    </p>
                    <h1 className={classes.contentTitle}>
                        8 . Notices Specific to Spendworkz Software
                    </h1>
                    <h1 className={classes.contentTitle}>
                        9. DMCA Copyright Infringement Claim
                    </h1>
                    <span className={classes.contentTitle}>
                        <b>
                            9.1 Notice of Claimed Infringement: Pursuant to Title 17, United
                            States Code, Section 512(c)(2),
                        </b>
                    </span>
                    <br />
                    <span >
                        notifications of claimed copyright infringement should be sent to{' '}</span>
                    <b>
                        <a href='mailto:sanjeev@spendworkz.com'>sanjeev@spendworkz.com </a>
                    </b>

                    <h1 className={classes.contentTitle}>
                        10. Links to Other Materials or Sites
                    </h1>

                    <p>
                        The Websites and/or the Materials may contain links to other Internet
                        sites. The linked sites are not under the control of Spendworkz and
                        Spendworkz is not responsible for the content of any linked site or any
                        link contained in a linked site. Spendworkz reserves the right to
                        terminate any link or linking program at any time. Spendworkz does not
                        endorse companies or products to which it links and reserves the right
                        to note as such on the Websites or the Materials. If you decide to
                        access or use linked sites, you do so entirely at your own risk.
                    </p>

                    <h1 className={classes.contentTitle}>
                        11. Exclusion of Warranty. Limitation of Liability. Indemnification
                    </h1>
                    <p className={classes.contentTitle}>
                        <b>11.1 Exclusion of Warranty: YOU EXPRESSLY UNDERSTAND AND AGREE THAT</b>
                    </p>
                    <p className={classes.contentTitle}>
                        <b> 11.2 UNLESS OTHERWISE EXPRESSLY STATED IN THE MATERIALS THEMSELVES
                            OR PURSUANT TO ANY LICENSE TERMS OR PERMISSIONS FOR THE MATERIALS,
                            THE WEBSITES AND THE MATERIALS ARE PROVIDED “AS IS” AND “AS
                            AVAILABLE” AND WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY
                            KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. SPENDWORKZ AND EACH OF
                            ITS SUPPLIERS OR LICENSORS EXPRESSLY DISCLAIM ANY AND ALL
                            REPRESENTATIONS OR WARRANTIES, INCLUDING WITHOUT LIMITATION THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                            PURPOSE, VALIDITY, TITLE, OR NON-INFRINGEMENT. ACCORDINGLY, YOUR USE
                            OF THE WEBSITES AND THE MATERIALS IS AT YOUR SOLE RISK.
                        </b>
                    </p>

                    <p>
                        11.3 SPENDWORKZ DOES NOT WARRANT THE ACCURACY, COMPLETENESS, OR
                        SUFFICIENCY OF ANY MATERIALS MADE AVAILABLE THROUGH THE WEBSITES AND
                        AS SUCH YOUR USE OF ANY MATERIALS OBTAINED THROUGH THE USE OF THE
                        WEBSITES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
                        SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEMS OR OTHER
                        DEVICES OR FOR ANY LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE
                        MATERIALS.
                    </p>
                    <p>
                        11.4 Limitation of Liability YOU EXPRESSLY UNDERSTAND AND AGREE THAT
                        SPENDWORKZ, ITS LICENSORS AND SUPPLIERS WILL NOT BE LIABLE TO YOU FOR:
                    </p>
                    <p>
                        11.5 ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION DIRECT,
                        SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, RESULTING
                        FROM ANY LOSS OF USE, LOSS OF DATA, LOST PROFITS, OR SIMILAR LOSS, IN
                        ANY WAY ARISING OUT OF OR IN CONNECTION WITH THE WEBSITES OR THE
                        MATERIALS, INCLUDING WITHOUT LIMITATION THE USE OR PERFORMANCE OF ANY
                        SOFTWARE, DOCUMENTS, SERVICES, OR INFORMATION MADE AVAILABLE ON THE
                        WEBSITES, REGARDLESS OF THE CAUSE OF ACTION OR THEORY OF LIABILITY
                        UNDER WHICH SUCH DAMAGES ARE SOUGHT (WHETHER IN AN ACTION FOR BREACH
                        OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE (INCLUDING STRICT
                        LIABILITY), OR OTHER ACTION IN TORT).
                    </p>
                    <p>
                        11.6 ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF
                        (a) ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY, OR
                        EXISTENCE OF ANY CONTENT IN OR ON THE WEBSITES, THE MATERIALS, OR THE
                        DOCUMENTS, ANY CHANGES MADE BY SPENDWORKZ THERETO, OR ANY PERMANENT OR
                        TEMPORARY CESSATION BY SPENDWORKZ IN THE PROVISION THEREOF, OR (b) YOUR
                        FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND
                        CONFIDENTIAL.
                    </p>
                    <p>
                        11.7 THE FOREGOING LIMITATIONS ON SPENDWORKZ’S LIABILITY WILL APPLY
                        WHETHER OR NOT SPENDWORKZ KNEW OF, WAS ADVISED OF, OR OTHERWISE SHOULD
                        HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES. BECAUSE SOME
                        JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                        CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS MAY NOT
                        APPLY TO YOU. ANY CAUSE OF ACTION YOU MAY HAVE WITH RESPECT TO YOUR
                        USE OF THE WEBSITES OR THE MATERIALS MUST BE COMMENCED WITHIN ONE (1)
                        YEAR AFTER THE CLAIM OR CAUSE OF ACTION ARISES. THE DISCLAIMERS AND
                        LIMITATIONS OF LIABILITY SET FORTH IN THE TERMS WILL APPLY
                        NOTWITHSTANDING THE FAILURE OF ANY ESSENTIAL PURPOSE.
                    </p>
                    <p>
                        11.8 Indemnification: You agree to indemnify, at Spendworkz's election
                        defend, and hold Spendworkz and each of its officers, directors,
                        employees, agents, representatives, partners, suppliers, and licensors
                        (the “Indemnified Parties”) harmless from and against all actions,
                        claims, or demands, and all losses, damages, liabilities, fees, fines,
                        penalties, costs, and expenses (including without limitation
                        attorneys’ fees and legal costs) arising from or relating to your
                        breach or violation of the Terms or any content you make available
                        through the Websites or Materials. You acknowledge and agree that
                        Spendworkz may, at any time, assume the exclusive defense and control
                        over any claim for which you owe indemnity to Spendworkz hereunder, and
                        you agree to fully cooperate with Intel, at Spendworkz's reasonable
                        request, in connection with Spendworkz's defense and investigation of
                        any such claim. You agree that you will not settle or compromise any
                        claim for which indemnity is owed hereunder without Spendworkz’s prior
                        written consent, which Spendworkz may refuse, withhold, condition, or
                        delay, no matter the reason, in its sole and absolute discretion.
                    </p>

                    <h1 className={classes.contentTitle}>
                        12. General{' '}
                    </h1>
                    <p>
                        12.1 The Websites and the Materials are controlled or made available
                        from Spendworkz’s offices located in the United States of America.
                        Spendworkz makes no representations or warranties that the Websites or
                        the Materials are appropriate or available for use in other locations,
                        or that your access to them from the territory in which you reside is
                        or will be lawful or in compliance with applicable local laws. Those
                        who choose to access the Websites or the Materials from other
                        locations do so on their own initiative and are responsible for
                        compliance with applicable local laws.
                    </p>
                    <p>
                        12.2 The Terms constitute the entire agreement between you and
                        Spendworkz and govern your use of the Websites and the Materials. Any
                        claim relating to the Websites and/or the Materials will be governed
                        by the laws of the State of Delaware, USA, without regard to its
                        conflict of law principles and each party will submit to the personal
                        and exclusive jurisdiction of the courts located within the State of
                        Delaware, USA. If any provision of the Terms is found by a court of
                        competent jurisdiction to be invalid, the parties nevertheless agree
                        that the court should endeavor to give effect to the parties'
                        intentions as reflected in the provision, and the other provisions of
                        the Terms will remain in full force and effect. The section and
                        paragraph headings in these Terms are for convenience of reference
                        only and must not affect the interpretation of this Agreement.
                    </p>
                </div>
            </div>
        </div>)

}

export default TermsPolicy;