import React, { useState, useEffect, ErrorInfo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const useErrorBoundary = (): ErrorBoundaryState => {
  const [errorState, setErrorState] = useState<ErrorBoundaryState>({
    hasError: false,
    error: null,
    errorInfo: null,
  });

  useEffect(() => {
    const handleError = (
      message: string,
      source: string,
      lineno: number,
      colno: number,
      error: Error
    ) => {
      setErrorState({
        hasError: true,
        error: error || new Error(message),
        errorInfo: null,
      });
    };

    window.onerror = handleError as OnErrorEventHandler;

    // network errors
    window.onerror = (message, source, lineno, colno, error) => {
      // Handle uncaught runtime errors
      console.error('Uncaught Runtime Error:', error);
    };

    return () => {
      window.onerror = null;
    };
  }, []);

  return errorState;
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const errorState = useErrorBoundary();
  const { t } = useTranslation();

  if (errorState.hasError) {
    // Render custom error UI or fallback UI
    return (
      <div>
        <h2>{t('Something went wrong:')}</h2>
        <p>{errorState.error && errorState.error.toString()}</p>
        <p>{errorState.errorInfo && errorState.errorInfo.componentStack}</p>
      </div>
    );
  }

  // Render children if no error occurred
  return <>{children}</>;
};

export default ErrorBoundary;
