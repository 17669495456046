import { createSlice } from '@reduxjs/toolkit';

interface InviteUserState {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  companyName:string | null;
  industryList:string | null;
  tenantId:string;
  email:string;
  role:string;
  status:string;
  lastLoggedIn:string | null;
  preferedTimeZone:string;
  permissions:[]

}



const initialState: InviteUserState = {
    _id: "string",
    firstName: "",
    lastName: "",
    companyName:"",
    industryList:"",
    tenantId:"",
    email:"",
    role:"",
    status:"",
    lastLoggedIn:"",
    preferedTimeZone:"",
    permissions:[]
};

const editUserSlice = createSlice({
  name: 'editUser',
  initialState,
  reducers: {
    updateUser(state, action) {
        console.log(action.payload)
      const {_id,firstName,lastName,companyName,industryList,tenantId,email,role,status,lastLoggedIn,preferedTimeZone,permissions} = action.payload;
      state._id =_id,
      state.firstName=firstName,
      state.lastName = lastName,
      state.companyName = companyName,
      state.industryList = industryList,
      state.tenantId = tenantId,
      state.email =email,
      state. role =role,
      state.status = status,
      state.lastLoggedIn = lastLoggedIn,
      state.preferedTimeZone = preferedTimeZone,
      state. permissions = permissions
    },
  },
});

export const { updateUser } = editUserSlice.actions;
export default editUserSlice.reducer;
