import { lazy } from 'react';

// project imports
import Loadable from '../components/uiLoadable/Loadable';
import CreatePassword from 'components/registration/createPassword/CreatePassword';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('../pages/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('../pages/auth/SignUp')));
const AuthLayout = Loadable(
  lazy(() => import('../layouts/AuthLayout'))
);

const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/ForgotPassword'))
);

const createPassword = Loadable(
  lazy(() => import('../pages/auth/CreatePassword'))
);
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/sign-up',
      element: <AuthRegister />,
      queryParamsConfig: {
        id: ':id',
        email:':email',
        userType:'userType'
      }
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
      
    }, {
      path: '/reset-password',
      element: <ForgotPassword />,
      queryParamsConfig: {
        token: ':token',
        id: ':id',
        email:':email'
      },
    },
    {
      path: '/create-password',
      element: <CreatePassword/>,
      queryParamsConfig: {
        id: ':id',
        email:':email'
      }
      
    }

  ],
};

export default AuthenticationRoutes;
