import { lazy } from "react";

// project imports
import MainLayout from "../layouts/MainLayout";
import Loadable from "../components/uiLoadable/Loadable";
import { Analytics } from "@mui/icons-material";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../pages/dashboard/buyer/ItemMasterList"))
);

const SpendCube = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/SpendCube"))
);
const SpendClassification = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/SpendClassification"))
);
const ExecutiveDashboard = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/ExecutiveDashboad"))
);

const Supplier = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/Supplier"))
);
const SupplierDetails = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/SupplierDetails"))
);

const MainDashboard = Loadable(
  lazy(() => import("../components/dashboard/dashboard"))
);

const MyProfileLayout = Loadable(
  lazy(
    () =>
      import("../components/profiles/myProfile/myProfileLayout/MyProfileLayout")
  )
);

const OrganisationProfileLayout = Loadable(
  lazy(
    () =>
      import(
        "../components/profiles/organisationProfile/OrganisationProfileLayout/OrganisationProfileLayout"
      )
  )
);

const UserManagement = Loadable(
  lazy(() => import("../components/userManagement/UserManagement"))
);

const EAuction = Loadable(
  lazy(() => import("../components/supplier/eauction/eauction"))
);
const EAuctionDetails = Loadable(
  lazy(() => import("../components/supplier/eauction/eauctiondetails/eauctiondetails"))
);
const UserInvite = Loadable(
  lazy(() => import("../components/inviteUser/InviteUser"))
);

const MyProjects = Loadable(
  lazy(() => import("../components/myProjects/MyProjects"))
);

const Events = Loadable(
  lazy(() => import("../components/events/eventsLayout"))
);

const CreateEvent = Loadable(
  lazy(() => import("../components/events/createEventLayout"))
);

const SupplierProjects = Loadable(
  lazy(() => import("../components/supplier/projects"))
);
const CreateRFILayout = Loadable(
  lazy(() => import("../components/myProjects/createRFILayout/CreateRFILayout"))
);
const ApprovalInbox = Loadable(
  lazy(
    () =>
      import(
        "../components/myProjects/myProjectApprovalInbox/myProjectApprovalInbox"
      )
  )
);

const SupplierOrganistaionProfileLayout = Loadable(
  lazy(
    () =>
      import(
        "../components/profiles/supplierOrganisationProfile/supplierOrganisationProfileLayout/SupplierOrganisationProfileLayout"
      )
  )
);

const SupplierOrganistaionDetails = Loadable(
  lazy(
    () =>
      import(
        "../components/profiles/supplierOrganisationProfile/supplierOrganisationDetails/SupplierOrganisationDetails"
      )
  )
);
const SupplierContactInformation = Loadable(
  lazy(
    () =>
      import(
        "../components/profiles/supplierOrganisationProfile/contactInformation/ContactInformation"
      )
  )
);

const SupplierResponse = Loadable(
  lazy(() => import("../components/SupplierResponse/SupplierResponse"))
);

const CubeAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/sourceToContract/CubeAnalytics"))
);

const CubeAnalyticsForSpendClassification = Loadable(
  lazy(
    () =>
      import(
        "../pages/dashboard/sourceToContract/CubeAnalyticsForClassification"
      )
  )
);
const CreateSupplierRFILayout = Loadable(
  lazy(() => import("components/supplier/supplierRFILayout/SupplierRFILayout"))
);

const ViewRFI = Loadable(
  lazy(() => import("components/myProjects/ViewRFI/ViewRFI"))
);

const ViewRFQ = Loadable(
  lazy(() => import("components/myProjects/ViewRFQ/ViewRFQ"))
);

const RFQLayout = Loadable(lazy(() => import("components/RFQ")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <MainDashboard />,
    },
    {
      path: "/source-to-contract/spendcube",
      element: <SpendCube />,
    },
    {
      path: "/source-to-contract/spend-categorization",
      element: <SpendClassification />,
    },
    {
      path: "/source-to-contract/executive-dashboard",
      element: <ExecutiveDashboard />,
    },
    {
      path: "/source-to-contract/supplier",
      element: <Supplier />,
    },
    {
      path: "/supplierDetails",
      element: <SupplierDetails />,
      queryParamsConfig: {
        id: ":id",
      },
    },
    {
      path: "/source-to-contract/spendcube/details/:id",
      element: <CubeAnalytics />,
    },

    {
      path: "/source-to-contract/spend-categorization/details/:id",
      element: <CubeAnalyticsForSpendClassification />,
    },
    {
      path: "/my-profile",
      element: <MyProfileLayout />,
    },
    {
      path: "/organisation",
      element: <OrganisationProfileLayout />,
    },
    {
      path: "/user-management",
      element: <UserManagement />,
    },
    {
      path: "/user-invite",
      element: <UserInvite />,
    },
    {
      path: "/user-edit",
      element: <UserInvite />,
      queryParamsConfig: {
        id: ":id",
        redirect: ":redirect",
      },
    },
    {
      path: "/user-details",
      exact: true,
      element: <UserInvite />,
      queryParamsConfig: {
        id: ":id",
      },
    },
    {
      path: "/source-to-contract/my-projects",
      element: <MyProjects />,
    },
    {
      path: "/eaction/events/details/:id",
      element: <EAuctionDetails />,
    },
    {
      path: "/eaction/events",
      element: <EAuction />,
    },
    {
      path: "/source-to-contract/events",
      element: <Events />,
    },
    {
      path: "/source-to-contract/approval-inbox",
      element: <ApprovalInbox />,
    },
    {
      path: "/supplier/my-projects",
      element: <SupplierProjects />,
    },
    {
      path: "/supplier/rfi",
      element: <CreateSupplierRFILayout />,
      queryParamsConfig: {
        id: ":id",
      },
    },

    {
      path: "/create-rfi/:process/",
      exact: true,
      element: <CreateRFILayout />,
      queryParamsConfig: {
        view: ":view",
        id: ":id",
        type: ":type?",
      },
    },
    {
      path: "/create-event/:id",
      element: <CreateEvent />,
      // children: [
      //   {
      //     path: "/create-event/lot",
      //     element: <SupplierOrganistaionDetails />,
      //   },
      // ],
    },
    {
      path: "/view-rfi/",
      exact: true,
      element: <ViewRFI />,
      queryParamsConfig: {
        id: ":id",
        supplierId: ":supplierId",
        type: ":type",
        status: ":status",
      },
    },
    {
      path: "/view-rfq/",
      exact: true,
      element: <ViewRFQ />,
      queryParamsConfig: {
        id: ":id",
        supplierId: ":supplierId",
        type: ":type",
        status: ":status",
      },
    },
    {
      path: "/my-projects/success",
      element: <MyProjects />,
    },

    {
      path: "/supplier-organisation-profile",
      element: <SupplierOrganistaionProfileLayout />,
      children: [
        {
          path: "/supplier-organisation-profile/organisation-details",
          element: <SupplierOrganistaionDetails />,
        },
        {
          path: "/supplier-organisation-profile/contact-information",
          element: <SupplierContactInformation />,
        },
      ],
    },
  ],
};

export default MainRoutes;
