import { Button } from '@mui/material';
import classes from './ButtonContained.module.css';

const ButtonContained = (props: any) => {
  return (
    <Button
      {...props}
      variant={props.variant ?? 'contained'}
      style={props.style}
      className={props?.disabled ? `${classes.button} ${props.className}` :  `${classes.button} ${props.className}  app-theme-background app-theme-color-white`}
      onClick={props.onClick}
    >
      {' '}
      {props.children}
    </Button>
  );
};

export default ButtonContained;
