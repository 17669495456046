import React, { SVGProps } from 'react';

const SpendClassificationIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (

<svg width={props.width ||"15"} height={props.height ||"13" }
viewBox="0 0 15 13" 
fill={props.color || '#fff'}
xmlns="http://www.w3.org/2000/svg">
<path d="M0.0826608 4.70293C0.0792162 5.08151 0.304263 5.32289 0.656761 5.49206C2.16894 6.21827 3.68112 6.94448 5.1933 7.67172C5.69851 7.91517 6.20027 8.1648 6.70892 8.40206C6.96153 8.51966 7.2348 8.52275 7.48625 8.40206C9.50249 7.42106 11.5164 6.43593 13.5304 5.45183C13.9851 5.23005 14.1803 4.82156 14.0344 4.38934C13.9724 4.20572 13.8473 4.06543 13.6613 3.97465C11.66 2.98849 9.6552 2.00749 7.65619 1.01617C7.27154 0.825338 6.91904 0.825338 6.5344 1.01617C4.57786 1.9817 2.61674 2.94104 0.655612 3.89935C0.295077 4.07575 0.0861051 4.32848 0.0815123 4.70499L0.0826608 4.70293ZM1.76248 4.66682C1.81759 4.63588 1.85433 4.61421 1.89337 4.59565C3.59156 3.76731 5.28975 2.94001 6.98679 2.10962C7.07864 2.06423 7.14179 2.08073 7.22217 2.11993C8.7952 2.88843 10.3694 3.65487 11.9424 4.42132C12.1009 4.49868 12.257 4.57605 12.4361 4.66476C12.3695 4.69983 12.3282 4.72459 12.2857 4.74522C10.6139 5.56014 8.94102 6.37404 7.27269 7.19309C7.1395 7.25807 7.04534 7.25498 6.91445 7.19102C5.24497 6.37197 3.57319 5.55808 1.90141 4.74316C1.85893 4.72253 1.81874 4.69777 1.76133 4.66579L1.76248 4.66682Z"
 fill={props.color || '#fff'}/>
<path d="M7.12759 12.8731C7.30671 12.805 7.4732 12.7544 7.62591 12.6812C8.96471 12.0323 10.3012 11.3783 11.6389 10.7254C12.2589 10.4231 12.8778 10.1199 13.4978 9.81864C13.9146 9.61646 14.0409 9.30183 13.84 8.97277C13.6896 8.72623 13.3118 8.59832 12.8812 8.81185C11.1497 9.67113 9.41136 10.5201 7.67528 11.3732C7.52831 11.4454 7.37675 11.5114 7.23552 11.5919C7.13678 11.6486 7.0587 11.6424 6.9588 11.5919C5.93461 11.0802 4.90927 10.5717 3.88278 10.0631C3.00326 9.62677 2.12488 9.18939 1.24536 8.75408C0.981276 8.62411 0.689633 8.64371 0.489846 8.79637C0.298097 8.94285 0.218871 9.21209 0.301542 9.43387C0.357804 9.58551 0.477216 9.68351 0.629927 9.75881C1.85391 10.3602 3.07674 10.9637 4.29957 11.5661C5.05164 11.9364 5.80027 12.3109 6.55693 12.674C6.73146 12.7575 6.9278 12.805 7.12759 12.8731Z"
fill={props.color || '#fff'}/>
<path d="M7.12759 10.7207C7.30671 10.6526 7.4732 10.6021 7.62591 10.5288C8.96471 9.88 10.3012 9.226 11.6389 8.57303C12.2589 8.27078 12.8778 7.96751 13.4978 7.6663C13.9146 7.46411 14.0409 7.14949 13.84 6.82043C13.6896 6.57389 13.3118 6.44597 12.8812 6.6595C11.1497 7.51878 9.41136 8.36775 7.67528 9.22084C7.52831 9.29305 7.37675 9.35907 7.23552 9.43953C7.13678 9.49626 7.0587 9.49007 6.9588 9.43953C5.93461 8.92788 4.90927 8.41933 3.88278 7.91077C3.00326 7.47443 2.12488 7.03705 1.24536 6.60174C0.981276 6.47176 0.689633 6.49136 0.489846 6.64403C0.298097 6.79051 0.218871 7.05975 0.301542 7.28153C0.357804 7.43317 0.477216 7.53116 0.629927 7.60647C1.85391 8.20786 3.07674 8.81131 4.29957 9.41374C5.05164 9.78406 5.80027 10.1585 6.55693 10.5216C6.73146 10.6052 6.9278 10.6526 7.12759 10.7207Z" 
fill={props.color || '#fff'}/>
</svg>
);

export default SpendClassificationIcon;
