import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useSelector, useDispatch } from 'react-redux';
import {
  setNotificationCounts,
  setShowNotificationPanel,
} from '../../features/dashboardSlice';
import Notification from '../Notification/Notification';
import { notificationPooling } from '../../services/apiHandler';

const NotificationIcon = () => {
  const dispatch = useDispatch();
  const dashboardStore = useSelector((state: any) => state.dashboard);

  const fetchNotifications = () => {
    notificationPooling()
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          const { notificationCount } = response.data.data;
          dispatch(setNotificationCounts(notificationCount));
        }else {
          dispatch(setNotificationCounts(0));
        }
      })
      .catch((error: any) => {}); 
  };

  React.useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000); // Poll every 60 seconds ( 1minute)
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const notificationCount = () => {
    if (dashboardStore.notificationCounts > 99) {
      return `99+`;
    }
    return dashboardStore.notificationCounts;
  };

  return (
    <React.Fragment>
      <div
        onClick={() => {
          dispatch(setShowNotificationPanel(true));
        }}
        style={styles.container}
      >
        <IconButton aria-label={notificationCount()}>
          <Badge
            badgeContent={notificationCount()}
            sx={{
              '& .MuiBadge-badge': {
                color: 'white',
                backgroundColor: 'red',
              },
            }}
          >
            <NotificationsOutlinedIcon
              sx={{
                position: 'relative',
                color: location.pathname === '/dashboard' ? '#fff' : '#455A64',
              }}
            />
          </Badge>
        </IconButton>
      </div>
    </React.Fragment>
  );
};

const styles = {
  container: {
    display: 'inline-block',
  },
};

export default NotificationIcon;
